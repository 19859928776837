<template>
  <div>
    <log-in v-if="!loggedIn"></log-in>
    <div v-if="loggedIn">
      <side-bar></side-bar>
      <verkoop-listener></verkoop-listener>
      <div class="appWrapper">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from "./components/SideBar.vue";
import LogIn from "./components/LogIn.vue";
import VerkoopListener from "./components/VerkoopListener.vue";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
export default {
  data() {
    return {
      selectedComponent: "dashboard-voorraad",
      loggedIn: false,
      user: "",
    };
  },
  components: {
    SideBar,
    LogIn,
    VerkoopListener,
  },
  methods: {
    toggleComponent(val) {
      this.selectedComponent = val;
    },
  },
  created() {
    localStorage.clear();
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.loggedIn = true;
      } else {
        console.log("No user is logged in!");
      }
    });
  },
  
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
}

.appWrapper {
  padding-top: 2rem;
}

body {
  margin: 0;
}

html {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}
</style>
