<template>
  <div class="rootDiv">
    <div v-if="showLoading" class="loadingContainer">
      <div class="honeycomb">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div class="dialog" v-if="!!show">
      <teleport to="body">
        <div class="backdrop">
          <transition>
            <div class="dialogView">
              <dialog open>
                <add-product @close="show = false"></add-product>
              </dialog>
            </div>
          </transition>
        </div>
      </teleport>
    </div>
    <div class="content" v-if="!showLoading">
      <font-awesome-icon
        style="margin-right: 3rem"
        class="icon"
        icon="plus"
        @click="show = true"
      />

      <h2>Producten Overzicht</h2>
      <table>
        <tr>
          <th>Productnaam</th>
          <th>Artikelnaam</th>
          <th>Artikelnummer</th>
          <th>Verpakkingseenheid</th>
          <th>Prijs Ex BTW</th>
          <th>Leverancier</th>
          <th>Korting</th>
        </tr>
        <tr
          v-for="i in stocks"
          :key="i.id"
          class="title"
          @click="reRoute(i.id)"
          :class="{ active: i.expanded, alert: i.prijzen.length === 0 }"
        >
          <td>{{ i.id || "" }}</td>
          <td>{{ getArtikelnaam(i) || "Ontbrekende Waarde" }}</td>
          <td>{{ getArtikelnummer(i) || "Ontbrekende Waarde" }}</td>
          <td>{{ getVerpakkingseenheid(i) || "Ontbrekende Waarde" }}</td>
          <td>{{ getPrijs(i) || "Ontbrekende Waarde" }}</td>
          <td>{{ getLeverancier(i) || "Ontbrekende Waarde" }}</td>
          <td>{{ getKorting(i) || "Ontbrekende Waarde" }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import AddProduct from "./AddProduct.vue";
import { useLoadLeveranciers, useLoadStock } from "../fb.js";
export default {
  setup() {
    let stocks = useLoadStock();
    let leveranciers = useLoadLeveranciers();
    return { stocks, leveranciers };
  },
  data() {
    return {
      show: false,
      showLoading: false,
      leverancier: "Natec",
    };
  },
  methods: {
    reRoute(id) {
      this.$router.push(`producten/${id}`);
    },
    getArtikelnaam(product) {
      const rec = product.prijzen.find(
        (_prod) => _prod.naam === this.leverancier && _prod.default === true
      );
      if (rec) {
        return rec.artikelNaam;
      }
    },
    getArtikelnummer(product) {
      const rec = product.prijzen.find(
        (_prod) => _prod.naam === this.leverancier && _prod.default === true
      );
      if (rec) {
        return rec.artikelNummer;
      }
    },
    getVerpakkingseenheid(product) {
      const rec = product.prijzen.find(
        (_prod) => _prod.naam === this.leverancier && _prod.default === true
      );
      if (rec) {
        return rec.verpakkingsEenheid;
      }
    },
    getPrijs(product) {
      const rec = product.prijzen.find(
        (_prod) => _prod.naam === this.leverancier && _prod.default === true
      );
      if (rec) {
        let p = rec.prijsExBtw.toString();
        if (p.includes(".")) {
          p = p.replace(".", ",");
          return `€${p}`;
        } else {
          return `€${p},00`;
        }
      }
    },
    getLeverancier(product) {
      const rec = product.prijzen.find(
        (_prod) => _prod.naam === this.leverancier && _prod.default === true
      );
      if (rec) {
        return rec.naam;
      }
    },
    getKorting(product) {
      const rec = product.prijzen.find(
        (_prod) => _prod.naam === this.leverancier && _prod.default === true
      );
      if (rec) {
        if (rec.korting) {
          return `${rec.korting}%`;
        }
      }
    },
  },

  components: { AddProduct },
  created() {
    this.showLoading = true;
    setTimeout(() => {
      this.showLoading = false;
    }, 2500);
  },
};
</script>

<style scoped>
.wrapperField {
  display: flex;
  width: 50%;
}

.contentWrapper {
  border: 1px solid red;
}

.titleText {
  margin-left: 2rem;
}

.title {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.title:hover {
  background-color: #44bace;
}
.title,
.description {
  border: 1px solid black;
  padding: 5px;
}

.description {
  border-bottom: 4px solid black;
}

.active {
  background-color: #44bace;
  color: #fff;
}

.alert {
  background-color: red !important;
  color: white;
}

.genContent {
  margin: auto;
  width: 90%;

  text-align: left;
}

.icon {
  border: 1px solid transparent;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  padding: 0.5rem;
  float: right;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  z-index: 10;
}

.icon:hover {
  border: 1px solid #44bace;
  color: white;
  background-color: #44bace;
}

.content {
  padding: 2rem;
}

.section {
  background-color: rgba(128, 128, 128, 0.78);
}
/*  */

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
}

.dialogView {
  margin: auto;
  /* left: 60%; */
  /* right: auto; */
  z-index: 15;
}

dialog {
  /* z-index: 100; */
  border: none;
  width: 80%;
  padding: 0;
  margin: auto;
  /* overflow-y: auto; */
  background-color: transparent;
}

.dialog-enter-from,
.dialog-leave-to {
  opacity: 0;
  transform: scale(0.8);
}

.dialog-enter-active {
  transition: all 0.3s ease-out;
}

.dialog-leave-active {
  transition: all 0.3 ease-in;
}

.dialog-enter-to,
.dialog-leave-from {
  opacity: 1;
  transform: scale(1);
}

table {
  margin: auto;
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 2rem;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

/* honeycomb css */

.loadingContainer {
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;
  overflow: hidden;
  height: 100vh;
  width: 100%;
}

/**
  * Honeycomb
  *
  * @author jh3y - jheytompkins.com
*/
@-webkit-keyframes honeycomb {
  0%,
  20%,
  80%,
  100% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  30%,
  70% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes honeycomb {
  0%,
  20%,
  80%,
  100% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  30%,
  70% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.honeycomb {
  height: 24px;
  position: relative;
  width: 24px;
}
.honeycomb div {
  -webkit-animation: honeycomb 2.1s infinite backwards;
  animation: honeycomb 2.1s infinite backwards;
  background: lightgrey;
  height: 12px;
  margin-top: 6px;
  position: absolute;
  width: 24px;
}
.honeycomb div:after,
.honeycomb div:before {
  content: "";
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  position: absolute;
  left: 0;
  right: 0;
}
.honeycomb div:after {
  top: -6px;
  border-bottom: 6px solid lightgrey;
}
.honeycomb div:before {
  bottom: -6px;
  border-top: 6px solid lightgrey;
}
.honeycomb div:nth-child(1) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  left: -28px;
  top: 0;
}
.honeycomb div:nth-child(2) {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
  left: -14px;
  top: 22px;
}
.honeycomb div:nth-child(3) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
  left: 14px;
  top: 22px;
}
.honeycomb div:nth-child(4) {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
  left: 28px;
  top: 0;
}
.honeycomb div:nth-child(5) {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
  left: 14px;
  top: -22px;
}
.honeycomb div:nth-child(6) {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
  left: -14px;
  top: -22px;
}
.honeycomb div:nth-child(7) {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
  left: 0;
  top: 0;
}
</style>
