import { createRouter, createWebHashHistory } from "vue-router";
import DashboardVoorraad from "./components/DashboardVoorraad.vue";
import VerkoopOverzicht from "./components/VerkoopOverzicht.vue";
import InkoopOverzicht from "./components/InkoopOverzicht.vue";
import MagazijnOverzicht from "./components/MagazijnOverzicht.vue";
import MagazijnView from "./components/MagazijnView.vue";
import ProductenOverzicht from "./components/ProductenOverzicht.vue";
import ProductView from "./components/ProductView.vue";
import MutatieOverzicht from "./components/MutatieOverzicht.vue";
import PageNotFound from "./components/PageNotFound.vue";
import VerkoopView from "./components/VerkoopView.vue";
import InkoopView from "./components/InkoopView.vue";
import RapportOverzicht from "./components/RapportOverzicht.vue";

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    { path: "/", redirect: "/dashboard" },
    { path: "/dashboard", component: DashboardVoorraad },
    { path: "/verkoop", component: VerkoopOverzicht },
    { path: "/verkoop/:id", component: VerkoopView },
    { path: "/inkoop", component: InkoopOverzicht },
    { path: "/inkoop/:id", component: InkoopView },
    { path: "/magazijn", component: MagazijnOverzicht },
    { path: "/magazijn/:id", component: MagazijnView, props: true },
    { path: "/producten", component: ProductenOverzicht },
    { path: "/producten/:id", component: ProductView, props: true },
    { path: "/mutaties", component: MutatieOverzicht },
    { path: "/reports", component: RapportOverzicht },
    { path: "/:notFound(.*)", component: PageNotFound },
    //goes last in line!
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return { savedPosition };
    }
    return { left: 0, top: 0 };
  },
});

export default router;
