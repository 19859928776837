<template>
  <div class="rootDiv">
    <h3>Benodigde Artikelen:</h3>
    <table v-if="stock">
      <tr>
        <th>Naam</th>
        <th>Artikelnummer</th>
        <th>Prijs Ex BTW</th>
        <th>Aantal</th>
        <th>Eenheid</th>
        <th>Totaal</th>
        <th>Nodig voor klant</th>
        <th>Overschot</th>
      </tr>
      <tr v-for="s in stock" :key="s.id">
        <td style="font-size: small">
          {{ (s.selectedName = getPrice(s, 2) || s.id) }}
        </td>
        <td>{{ s.artikelNummer = getArtikelNummer(s) || "" }}</td>
        <td>€{{ (s.selectedPrice = getPrice(s, 0)) }}</td>
        <td>{{ (s.hoeveelheid = getAmount(s, 0) || 0) }}</td>
        <td>x {{ (s.verpakkingsEenheid = getPrice(s, 1)) }}</td>
        <td>{{ (s.toegevoegd = getTotal(s, 1)) }}</td>
        <td>{{ (s.nodig = getAmount(s, 1) || 0) }}</td>
        <td>{{ (s.overschot = getOverschot(s)) }}</td>
      </tr>
    </table>

    <h3>Magazijn voor (eventuele) overschotten:</h3>
    <select v-model="klantMagazijn">
      <option default value="">
        Kies het magazijn voor de eventuele overschotten
      </option>
      <option v-for="m in magazijnen" :value="m.bedrijf" :key="m.id">
        {{ m.bedrijf }}
      </option>
    </select>

    <h3>Aanvullende items:</h3>
    <select v-model="product">
      <option v-for="s in st" :key="s" :value="s">{{ s.id }}</option>
    </select>
    <select v-if="product" v-model="subProd">
      <option v-for="sub in product.prijzen" :key="sub" :value="sub">
        {{ sub.artikelNaam }}
      </option>
    </select>
    <button class="btn" v-if="subProd" @click="addProduct">Toevoegen</button>

    <table v-if="extraStock.length > 0">
      <tr>
        <th>Naam</th>
        <th>Artikelnummer</th>
        <th>Prijs Ex BTW</th>
        <th>Aantal</th>
        <th>Eenheid</th>
        <th>Toegevoegd</th>
      </tr>
      <tr v-for="s in extraStock" :key="s.id">
        <td style="font-size: small">
          {{ s.selectedName }}
          <!-- {{ (s.selectedName = getPrice(s, 2) || s.id) }} -->
        </td>
        <td style="font-size: small">
          {{ s.artikelNummer }}
        </td>
        <td>€ {{ s.selectedPrice }}</td>
        <td v-if="!s.toegevoegd">
          <input type="number" v-model="s.hoeveelheid" />
        </td>
        <td v-else>{{ s.hoeveelheid }}</td>
        <td style="text-align: center">x {{ s.verpakkingsEenheid }}</td>

        <td v-if="!s.toegevoegd && s.hoeveelheid">
          <button
            class="btn"
            style="margin: auto"
            @click="s.toegevoegd = s.hoeveelheid * s.verpakkingsEenheid"
          >
            toevoegen
          </button>
        </td>
        <td v-if="!s.toegevoegd && !s.hoeveelheid">0</td>
        <td v-if="s.toegevoegd">{{ s.toegevoegd }}</td>
      </tr>
    </table>
    <button class="btn" @click="addKlantInkoop" v-if="klantMagazijn">
      Opslaan
    </button>
  </div>
</template>

<script>
import { useLoadMagazijn, useLoadStock } from "@/fb";
export default {
  setup() {
    let st = useLoadStock();
    let magazijnen = useLoadMagazijn();
    return { st, magazijnen };
  },
  data() {
    return {
      product: "",
      subProd: "",
      items: "",
      stock: "",
      showAlert: false,
      errorProducts: [],
      extraStock: [],
      klantMagazijn: "",
      factuurBedrag: 0,
    };
  },
  props: ["klant", "leverancier"],

  methods: {
    async addKlantInkoop() {
      const array = [];
      const bestelItems = [];
      this.stock.forEach((_stock) => {
        array.push(_stock);
      });
      if (this.extraStock.length > 0) {
        this.extraStock.forEach((_extra) => {
          array.push(_extra);
        });
      }
      array.forEach((prod) => {
       const doc = {
            artikelNummer: prod.artikelNummer,
            aantal: prod.toegevoegd,
            naam: prod.selectedName,
            nodig: prod.nodig || 0,
            overschot: prod.overschot || 0,
            geleverd: false,
            status: "",
          };
          bestelItems.push(doc);
        this.factuurBedrag =
          this.factuurBedrag + prod.toegevoegd * prod.selectedPrice;
      });

         

      const d = new Date().valueOf();
      const doc = {
        afleverLocatie: this.klant.adres + ", " + this.klant.postcode,
        bestelDatum: d,
        bestelItems: bestelItems,
        bestelnummer: d,
        factuurBedrag: this.factuurBedrag,
        id: d,
        leverancier: this.leverancier,
        verwerkt: false,
        volledigGeleverd: false,
        type: "klant",
        klantMagazijn: this.klantMagazijn,
        klantLink :this.klant.id,
        klantNaam: this.klant.naam,
        klantBesteld: true,
      };
      const log = {
        afterValue: doc,
        collection: "Inkoop",
        recordId: doc.bestelDatum.toString(),
        type: "create",
        timeStamp: doc.bestelDatum,
      };
      await this.$store.dispatch("createInkoop", doc);
      await this.$store.dispatch("setVerkoopToBesteld", {
        magazijn: 'klant',
        id: log.recordId,
      });
      await this.$store.dispatch("createLog", log);
      this.$emit("close")

      
    },
    addProduct() {
      const doc = {
        artikelNummer: this.subProd.artikelNummer,
        hoeveelheid: 0,
        id: this.product.id,
        selectedName: this.subProd.artikelNaam,
        verpakkingsEenheid: this.subProd.verpakkingsEenheid,
        selectedPrice: this.subProd.prijsExBtw,
        zonnepaneel: this.product.zonnepaneel,
      };
      this.extraStock.push(doc);
      this.product = "";
      this.subProd = "";
    },
    getAmount(val, type = 0) {
      let stockVal = 0;
  
      const item = this.items.find((_items) => _items.naam === val.id);
      if (item && type === 1) {
        stockVal = item.aantal;
      } else {
        stockVal = Math.ceil(item.aantal / val.verpakkingsEenheid);
      }
      return stockVal;
      // }
    },
    getPrice(stock, type) {
      let returnVal = 0;
      if (type === 1) {
        returnVal = 1;
      }
      const d = new Date().valueOf();
      stock.prijzen.forEach((val) => {
        if (val.naam === this.leverancier) {
          if (
            d >= val.prijsVan &&
            (d <= val.prijsTot || val.prijsTot === "nvt")
          ) {
            if (type === 0) {
              returnVal = val.prijsExBtw;
            } else if (type === 1) {
              if (val.verpakkingsEenheid) {
                returnVal = val.verpakkingsEenheid;
                returnVal = 30;
              }
            }
          }
        }
      });
      return returnVal;
    },
    getTotal(stock) {
      return stock.hoeveelheid * stock.verpakkingsEenheid;
    },
    getOverschot(stock) {
      return stock.toegevoegd - stock.nodig;
    },
    getArtikelNummer(stock) {
      let returnVal = "";
      const d = new Date().valueOf();
      stock.prijzen.forEach((val) => {
        if (val.naam === this.leverancier) {
          if (
            d >= val.prijsVan &&
            (d <= val.prijsTot || val.prijsTot === "nvt") &&
            !stock.zonnepaneel
          ) {
            returnVal = val.artikelNummer;
          } else if (
            stock.zonnepaneel &&
            stock.selectedName === val.artikelNaam
          ) {
            returnVal = val.artikelNummer;
          }
        }
      });
      return returnVal;
    },
  },
  created() {
    setTimeout(() => {
      const s = [];
      this.items = this.klant.bestelItems.filter(
        (_items) => _items.magazijn === "klant" && _items.status === "Gereserveerd bij klant"
      );
      this.items.forEach((_items) => {
        const rec = this.st.find((_st) => _st.id === _items.naam);
        if (rec) {
          s.push(rec);
        }
      });
      this.stock = s;
    }, 500);
  },
};
</script>

<style scoped>
.btn {
  display: block;
  cursor: pointer;
  margin-top: 2rem;
  border: none;
  padding: 0.5rem 1rem;
  color: #fff;
  background-color: #44bace;
  border-radius: 12px;
  border: 2px solid transparent;
  transition: 0.3s all ease-in-out;
}

.btn:hover {
  border: 2px solid #358391;
}

input,
select {
  width: 60%;
  border: 1px solid #cccccc;
  padding: 1rem 1rem 1rem;
  border-radius: 0.4rem;
  background-color: #fff;
  transition: 1.5s all ease-in-out;
}

input:focus {
  width: 60%;
  border: 1px solid #44bace;
  padding: 1rem 1rem 1rem;
  border-radius: 0.4rem;
  background-color: lightgrey;
}

table {
  margin: auto;
  margin-top: 1rem;
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 2rem;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}
</style>
