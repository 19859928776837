<template>
  <table>
    <tr>
      <th>Product</th>
      <th>Voorraad {{ selectedMagazijn.bedrijf }}</th>
      <th>Voorraad {{ receivingMagazijn.bedrijf }}</th>
      <th>Verwerken</th>
    </tr>
    <tr v-for="s in selectedMagazijn.stock" :key="s.productNaam">
      <td>{{ s.productNaam }}</td>
      <td>{{ getVoorraadSelected(s) }}</td>
      <td><input v-model="s.numberToTransfer" type="number" /></td>
      <td>
        <button v-if="s.numberToTransfer" class="btn" @click="verwerken(s)">
          Verwerken
        </button>
      </td>
    </tr>
  </table>
</template>

<script>
export default {
  data() {
    return {
      numberToTransfer: 0,
      recMag: "",
    };
  },
  props: ["receivingMagazijn", "selectedMagazijn"],
  methods: {
    getVoorraadSelected(value) {
      const returnValue = this.selectedMagazijn.stock.filter(
        (val) => val.productNaam === value.productNaam
      );
      return returnValue[0].fysiekStock;
    },
    async verwerken(stock) {
      let found = false;
      if (stock.numberToTransfer <= stock.fysiekStock) {
        await this.recMag.stock.forEach((_receiving) => {
          if (_receiving.productNaam === stock.productNaam) {
            found = true;
            _receiving.fysiekStock =
              _receiving.fysiekStock + stock.numberToTransfer;
          }
        });
        if (!found) {
          const doc = {
            adminStock: 0,
            artikelNummer: stock.artikelNummer,
            bijgewerkt: false,
            fysiekStock: stock.numberToTransfer,
            productNaam: stock.productNaam,
            saved: true,
          };
          this.recMag.stock.push(doc);
        }
        await this.selectedMagazijn.stock.forEach((_stock) => {
          if (_stock.numberToTransfer) {
            _stock.fysiekStock = _stock.fysiekStock - _stock.numberToTransfer;
            delete _stock.numberToTransfer;
          }
        });

        setTimeout(() => {
          const d = new Date().valueOf();
          const doc1 = {
            id: this.selectedMagazijn.id,
            content: this.selectedMagazijn.stock,
          };

          const doc2 = {
            id: this.recMag.id,
            content: this.recMag.stock,
          };

          const log1 = {
            afterValue: this.selectedMagazijn,
            collection: "Magazijnen",
            recordId: this.selectedMagazijn.id,
            type: "fysiek",
            timeStamp: d,
          };

          const log2 = {
            afterValue: this.recMag,
            collection: "Magazijnen",
            recordId: this.recMag.id,
            type: "fysiek",
            timeStamp: d,
          };

          this.$store.dispatch("createLog", log1);
          this.$store.dispatch("createLog", log2);
          this.$store.dispatch("saveStock", doc1);
          this.$store.dispatch("saveStock", doc2);
          this.$emit("close");
        }, 0);
      }
    },
  },
  created() {
    this.recMag = this.receivingMagazijn;
    if (!this.receivingMagazijn.stock) {
      this.recMag.stock = [];
    }
  },
};
</script>

<style scoped>
.btn {
  cursor: pointer;
  /* margin-top: 2rem; */
  border: none;
  padding: 0.5rem 1rem;
  color: #fff;
  background-color: #44bace;
  border-radius: 12px;
  border: 2px solid transparent;
  transition: 0.3s all ease-in-out;
  /* vertical-align: middle; */
}

.btn:hover {
  border: 2px solid #358391;
}

input,
select {
  width: 60%;
  border: 1px solid #cccccc;
  padding: 1rem 1rem 1rem;
  border-radius: 0.4rem;
  background-color: #fff;
  transition: 1.5s all ease-in-out;
}

input:focus {
  width: 60%;
  border: 1px solid #44bace;
  padding: 1rem 1rem 1rem;
  border-radius: 0.4rem;
  background-color: lightgrey;
}

table {
  margin: auto;
  margin-top: 1rem;
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 2rem;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

.wrapper {
  display: flex;
}

.left {
  width: 50%;
}
</style>
