<template>
  <div class="rootDiv">
    <!-- <magazijn-view
      v-if="view"
      :magazijn="selectedMagazijn"
      @close="closing"
    ></magazijn-view> -->
    <div class="dialog" v-if="!!show">
      <teleport to="body">
        <div class="backdrop">
          <transition>
            <div class="dialogView">
              <dialog open>
                <magazijn-edit
                  v-if="edit"
                  :magazijn="selectedMagazijn"
                  @close="closing"
                ></magazijn-edit>
              </dialog>
            </div>
          </transition>
        </div>
      </teleport>
    </div>
    <div class="wrapperOverzicht" v-if="!view">
      <font-awesome-icon
        style="margin-right: 3rem"
        class="icon"
        icon="plus"
        @click="(show = true), (edit = true)"
      />
      <h1>Magazijn overzicht</h1>

      <div class="grid">
        <div v-for="magazijn of magazijnen" :key="magazijn.id">
          <!-- <div
            class="card"
            @click="(view = true), (selectedMagazijn = magazijn)"
            :class="{
              inactive: !magazijn.actief,
              active: magazijn.actief && !magazijn.showAlert,
              alert: magazijn.showAlert,
            }"
          > -->
                    <div
            class="card"
            @click="reRoute(magazijn)"
            :class="{
              inactive: !magazijn.actief,
              active: magazijn.actief && !magazijn.showAlert,
              alert: magazijn.showAlert,
            }"
          >
            <b class="header">{{ magazijn.bedrijf }}</b>
            <div class="wrapper">
              <div class="leftSide">
                <p>Contactpersoon: {{ magazijn.contactPersoon }}</p>
                <p>
                  Email 1:
                  <a :href="`mailto:${magazijn.email1}`"
                    >{{ magazijn.email1 }}
                  </a>
                </p>
                <p>
                  Email 2:
                  <a :href="`mailto:${magazijn.email2}`"
                    >{{ magazijn.email2 }}
                  </a>
                </p>
                <p>
                  Telefoonnummer:
                  <a :href="computePhone(magazijn.telefoonNummer)">{{
                    magazijn.telefoonNummer
                  }}</a>
                </p>
                <p>Adres: {{ magazijn.adres }}</p>
                <p>Postcode: {{ magazijn.postCode }}</p>
                <p>Stad: {{ magazijn.stad }}</p>
              </div>
              <div class="rightSide">
                <p>Agenda naam: {{ magazijn.agendaNaam }}</p>
                <p>Bestellen op: {{ magazijn.bestellen }}</p>
                <p>Levering op: {{ magazijn.levering }}</p>
                <p>Voorraadcheck: {{ magazijn.voorraadCheck }}</p>
                <p>Opmerking: {{ magazijn.opmerking }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MagazijnEdit from "./MagazijnEdit.vue";
// import MagazijnView from "./MagazijnView.vue";
import { useLoadMagazijn } from "../fb.js";

export default {
  components: { MagazijnEdit, 
  // MagazijnView
   },
  setup() {
    let magazijnen = useLoadMagazijn();

    return { magazijnen };
  },

  data() {
    return {
      show: false,
      edit: false,
      view: false,
      selectedMagazijn: "",
    };
  },
  methods: {
    reRoute(magazijn) {
      this.$router.push({path: `magazijn/${magazijn.id}`});
    },
    computePhone(val) {
      let nr = val;
      nr = nr.substring(1);
      return `tel:+31${nr}`;
    },
    closing() {
      this.show = false;
      this.edit = false;
      this.view = false;
      this.selectedMagazijn = "";
    },
  },
};
</script>

<style scoped>
p {
  margin: 0.5rem;
}

a {
  padding: 0.5rem 0;
}

h1 {
  margin-left: 2rem;
}

.grid {
  overflow: auto;
  margin: 1rem;
  display: grid;
  grid-template-columns: 33% 33% 33%;
}

.card {
  font-size: 0.9rem;
  margin: 1rem;
  padding: 0.5rem;
  min-height: 18rem;
  border-radius: 12px;
  transition: 0.2s all ease-in-out;
  cursor: pointer;
}

.alert {
  color: black;
  border: 2px solid orange;
  box-shadow: rgba(241, 194, 4, 0.8) 0px 3px 8px;
}

.active {
  color: black;
  border: 2px solid green;
  box-shadow: rgba(82, 245, 39, 0.8) 0px 3px 8px;
}

.active:hover {
  background-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(82, 245, 39, 1.8) 8px 8px 15px;
}

.inactive {
  color: black;
  border: 2px solid red;
  box-shadow: rgba(255, 0, 0, 0.8) 0px 3px 8px;
}

.inactive:hover {
  box-shadow: rgba(255, 0, 0, 0.8) 8px 8px 15px;
}

.icon {
  border: 1px solid transparent;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  padding: 0.5rem;
  float: right;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  z-index: 10;
}

.icon:hover {
  border: 1px solid #44bace;
  color: white;
  background-color: #44bace;
}

.leftSide,
.rightSide {
  width: 50%;
}

.wrapper {
  display: flex;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
}

.dialogView {
  position: absolute;
  margin: auto;
  left: 60%;
  right: auto;
  top: 0;
  z-index: 15;
}

dialog {
  max-width: 40rem;
  max-height: 60rem;
  /* z-index: 100; */
  border: none;
  overflow-y: auto;
  background-color: transparent;
}

.dialog-enter-from,
.dialog-leave-to {
  opacity: 0;
  transform: scale(0.8);
}

.dialog-enter-active {
  transition: all 0.3s ease-out;
}

.dialog-leave-active {
  transition: all 0.3 ease-in;
}

.dialog-enter-to,
.dialog-leave-from {
  opacity: 1;
  transform: scale(1);
}

@media (min-width: 768px) {
  dialog {
    left: calc(50% - 30rem);
    width: 60rem;
  }
}
</style>
