<template>
  <div class="rootDiv">
    <div class="content">
      <h2>Mutatie overzicht</h2>
      <div v-for="m of mutaties" :key="m.timeStamp">
        <div
          class="title"
          @click="m.expanded = !m.expanded"
          :class="{ active: m.expanded, alert: m.itemsInBackorder }"
        >
          <div class="titleText">
            <b
              >{{ getDate(m.timeStamp) }} in de collectie {{ m.collection }}
            </b>
          </div>
          <div>
            <span v-if="m.expanded">&#x2191;</span>
            <span v-else>&#x2193;</span>
          </div>
        </div>
        <div class="description" v-if="m.expanded">
          <mutatie-view :initialValue="m"></mutatie-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { useLoadMutaties } from "../fb.js";
import MutatieView from "./MutatieView.vue";

export default {
  name: "App",
  setup() {
    let mutaties = useLoadMutaties();

    return { mutaties };
  },
  components: { MutatieView },
  data() {
    return {
      show: false,
    };
  },

  methods: {
    getDate(date) {
      date = +date;
      return this.moment(date).format("DD-MM-YYYY, HH:mm:ss");
    },
    closing() {
      this.show = false;
    },
  },
  created() {
    this.moment = moment;
  },
};
</script>
<style scoped>
.content {
  padding: 2rem;
}

.alert {
  background-color: orange;
  /* opacity: 0.6; */
}

.iconBack {
  transform: rotate(45deg);
  padding: 0.5rem;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid black;
  float: right;
  cursor: pointer;
}

.titleText {
  margin-left: 2rem;
}
.title {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  transition: all 0.3s ease-in-out;
}
.title:hover {
  background-color: #44bace;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}
.title,
.description {
  border: 1px solid black;
  padding: 5px;
}

.active {
  background-color: #44bace;
  color: #fff;
}

.icon {
  border: 1px solid transparent;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  padding: 0.5rem;
  float: right;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  z-index: 10;
}

.icon:hover {
  border: 1px solid #44bace;
  color: white;
  background-color: #44bace;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
}

.dialogView {
  margin: auto;
  /* left: 60%; */
  /* right: auto; */
  z-index: 15;
}

dialog {
  max-width: 60rem;
  max-height: 90vh;
  /* z-index: 100; */
  border: none;
  overflow-y: auto;
  background-color: transparent;
}

button {
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 12px;
  background-color: #44bace;
  cursor: pointer;
  color: #fff;
}

.dialog-enter-from,
.dialog-leave-to {
  opacity: 0;
  transform: scale(0.8);
}

.dialog-enter-active {
  transition: all 0.3s ease-out;
}

.dialog-leave-active {
  transition: all 0.3 ease-in;
}

.dialog-enter-to,
.dialog-leave-from {
  opacity: 1;
  transform: scale(1);
}
</style>
