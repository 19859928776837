<template>
  <div
    @click="showMessage = false"
    class="message"
    :class="{ animate: !showMessage }"
  >
    Installaties van vandaag en eerder verwerken!
  </div>
</template>

<script>
import { useLoadMagazijn, useLoadVerkoop } from "../fb.js";

export default {
  data() {
    return {
      selectedVerkoop: "",
      selectedMagazijn: "",
      showMessage: false,
    };
  },
  setup() {
    let verkoop = useLoadVerkoop();
    let magazijnen = useLoadMagazijn();
    return { verkoop, magazijnen };
  },

  methods: {
    async afronden(selectedVerkoop) {
      selectedVerkoop.afgerond = true;

      selectedVerkoop.bestelItems.forEach((_items) => {
        if (
          _items.status !== "Niet gereserveerd" &&
          _items.magazijn !== "klant"
        ) {
          this.afrondItem(_items, selectedVerkoop);
        }
      });
    },

    async afrondItem(item, selectedVerkoop) {
      selectedVerkoop.autoInstall = false;
      let mag = this.magazijnen.find((val) => val.bedrijf === item.magazijn);
      const foundObj = mag.stock.find(
        (_stock) => _stock.productNaam === item.naam
      );

      foundObj.fysiekStock = foundObj.fysiekStock + item.aantal * -1;

      item.status = "Afgerond";
      const d = new Date().valueOf();
      const log2 = {
        afterValue: mag,
        collection: "Magazijnen",
        recordId: mag.id,
        type: "fysiek",
        timeStamp: d.toString(),
      };
      this.$store.dispatch("createLog", log2);
      await this.$store.dispatch("saveStock", {
        id: mag.id,
        content: mag.stock,
      });

      const log = {
        afterValue: selectedVerkoop,
        collection: "Verkoop",
        recordId: selectedVerkoop.id,
        type: "edit",
        timeStamp: d.toString(),
      };

      this.$store.dispatch("createLog", log);
      this.$store.dispatch("setVerkoopChanges", selectedVerkoop);
    },
  },
  watch: {
    async verkoop() {
      setTimeout(() => {
        const v = this.verkoop.filter((val) => val.autoInstall === true);
        if (v.length > 0) {
          this.showMessage = true;
          v.forEach((_verkoop) => {
            this.afronden(_verkoop);
          });
        } else {
          this.showMessage = false;
        }
      }, 2500);
    },
  },
};
</script>

<style scoped>
.message {
  position: absolute;
  top: 0;
  right: 0;
  border: 1px solid black;
  padding: 0.5rem 1rem;
  background-color: #60ab2d;
  font-weight: 700;
  color: white;
  cursor: pointer;
  padding-right: 3rem;
  border-radius: 12px;
  margin-top: 4rem;
  margin-right: -1rem;
  z-index: 100;
  transition: all 0.3s ease-in-out;
}

.animate {
  transform: translateX(500px);
}
</style>
