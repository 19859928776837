import firebase from "firebase/app";
import "firebase/firestore";
import {ref, onUnmounted} from "vue";

const firebaseConfig = {
  apiKey: "AIzaSyAfXFXT-dd5BfRF6Rb5kZlxTMmhShmDwGs",
  authDomain: "voorraad-beheer.firebaseapp.com",
  projectId: "voorraad-beheer",
  storageBucket: "voorraad-beheer.appspot.com",
  messagingSenderId: "521533494598",
  appId: "1:521533494598:web:2a06426d1fe61a24c6c147",
  measurementId: "G-2FDVJ2DB3S"
};
firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const inkoopCollection = db.collection("Inkoop").orderBy("bestelDatum", "desc");

export const useLoadInkoop = () => {
  const inkoop = ref([]);
  const close = inkoopCollection.onSnapshot(snapshot => {
    inkoop.value = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
  })
  onUnmounted(close);
  return inkoop;
}

const magazijnenCollection = db.collection("Magazijnen");

export const useLoadMagazijn = () => {
  const magazijnen = ref([]);
  const close = magazijnenCollection.onSnapshot(snapshot => {
    magazijnen.value = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
  })
  onUnmounted(close);
  return magazijnen;
}

const stockCollection = db.collection("Producten");
// const stockCollection = db.collection("Stock");
export const useLoadStock = () => {
  const stocks = ref([]);
  const close = stockCollection.onSnapshot(snapshot => {
    stocks.value = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
  })
  onUnmounted(close);
  return stocks;
}

const leverancierCollection = db.collection("Leveranciers");
export const useLoadLeveranciers = () => {
  const leveranciers = ref([]);
  const close = leverancierCollection.onSnapshot(snapshot => {
    leveranciers.value = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
  })
  onUnmounted(close);
  return leveranciers;
}

// const verkoopCollection = db.collection("Test").orderBy("installatieDatum", "asc");
const verkoopCollection = db.collection("Verkoop").orderBy("installatieDatum", "asc");
export const useLoadVerkoop = () => {
  const verkoop = ref([]);
  const close = verkoopCollection.onSnapshot(snapshot => {
    verkoop.value = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
  })
  onUnmounted(close);
  return verkoop;
}

const mutatieCollection = db.collection("Mutaties").orderBy("timeStamp", "asc");
export const useLoadMutaties = () => {
  const mutatie = ref([]);
  const close = mutatieCollection.onSnapshot(snapshot => {
    mutatie.value = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data()}))
  })
  onUnmounted(close);
  return mutatie;
}




// if (firebase.apps.length === 0) {
//   console.log("Should send email");
//   fetch(
//     "https://us-central1-online-offerte-tool.cloudfunctions.net/sendErrorMessage",
//     { method: "GET", mode: "no-cors" }
//   ).then((res) => {
//     console.log(res.status);
//   });
// } else {
//   console.log("everything is working fine!");
// }

db.settings({ timestampsInSnapshots: true, merge: true });
export default db;
