<template>
  <div class="rootDiv">
    <div class="dialog" v-if="!!show">
      <teleport to="body">
        <div class="backdrop">
          <transition>
            <div class="dialogView">
              <dialog open>
                <magazijn-edit
                  v-if="edit"
                  :magazijn="selectedMagazijn"
                  @close="(edit = false), (show = false)"
                ></magazijn-edit>
                <magazijn-transfer
                  v-if="transfer"
                  :selectedMagazijn="selectedMagazijn"
                  @close="(transfer = false), (show = false)"
                ></magazijn-transfer>
              </dialog>
            </div>
          </transition>
        </div>
      </teleport>
    </div>
    <font-awesome-icon class="icon" icon="arrow-left" @click="reRoute()" />
    <h3>Magazijn: {{ selectedMagazijn.bedrijf }}</h3>
    <div
      class="content"
      :class="{
        inactive: !selectedMagazijn.actief,
        active: selectedMagazijn.actief && !selectedMagazijn.showAlert,
        alert: selectedMagazijn.showAlert,
      }"
      v-if="selectedMagazijn"
    >
      <font-awesome-icon
        v-if="!showLog"
        class="icon"
        icon="file-signature"
        @click="showLog = true"
        title="Log bekijken"
      />
      <font-awesome-icon
        v-else
        class="icon"
        icon="arrow-left"
        @click="showLog = false"
        title="Terug"
      />

      <font-awesome-icon
        class="icon"
        icon="pen"
        @click="(show = true), (edit = true), magazijn"
        title="Magazijn bewerken"
      />
      <div class="_content">
        <div class="leftSide">
          <p>
            Gebruik 3-fase producten: 
            <label class="switch">
              <input @change="set3Fase" v-model="selectedMagazijn.useDrieFase" type="checkbox" unchecked />
              <span class="slider round"></span>
            </label>
          </p>
          <p>Installateur: {{ selectedMagazijn.installateur }}</p>
          <p>Contactpersoon: {{ selectedMagazijn.contactPersoon }}</p>
          <p>
            Email 1:
            <a :href="`mailto:${selectedMagazijn.email1}`"
              >{{ selectedMagazijn.email1 }}
            </a>
          </p>
          <p>
            Email 2:
            <a :href="`mailto:${selectedMagazijn.email2}`"
              >{{ selectedMagazijn.email2 }}
            </a>
          </p>
          <p>
            Telefoonnummer:
            <a :href="computePhone(selectedMagazijn.telefoonNummer)">{{
              selectedMagazijn.telefoonNummer
            }}</a>
          </p>
          <p>Adres: {{ selectedMagazijn.adres }}</p>
          <p>Postcode: {{ selectedMagazijn.postCode }}</p>
          <p>Stad: {{ selectedMagazijn.stad }}</p>
          <p>Agenda naam: {{ selectedMagazijn.agendaNaam }}</p>
          <p>Bestellen op: {{ selectedMagazijn.bestellen }}</p>
          <p>Levering op: {{ selectedMagazijn.levering }}</p>
          <p>Voorraadcheck: {{ selectedMagazijn.voorraadCheck }}</p>
          <p>Opmerking: {{ selectedMagazijn.opmerking }}</p>

          <button class="transfer" @click="toggleTransfer">
            Producten Verplaatsen
          </button>
        </div>
        <div class="rightSide">
          <magazijn-log
            v-if="showLog"
            :magazijn="selectedMagazijn"
          ></magazijn-log>
          <div v-if="!showLog" class="productTable">
            <table>
              <tr>
                <th>Naam</th>
                <th>Administatieve voorraad</th>
                <th>Fysieke voorraad</th>
                <th>Bewerken</th>
              </tr>
              <tr
                v-for="(prod, index) in selectedMagazijn.stock"
                :key="prod.productName"
              >
                <td>{{ prod.productNaam }}</td>
                <td><input type="number" v-model="prod.adminStock" /></td>
                <td><input type="number" v-model="prod.fysiekStock" /></td>
                <td>
                  <p
                    style="font-size: 0.9rem; text-align: center"
                    v-if="prod.bijgewerkt"
                  >
                    Wachten opslaan
                  </p>
                  <button
                    @click="update(index, prod), (prod.bijgewerkt = true)"
                    class="btn"
                    style="background-color: orange"
                    v-if="prod.saved && !prod.bijgewerkt"
                  >
                    Bijwerken
                  </button>
                  <button
                    @click="remove(index)"
                    class="btn"
                    style="background-color: red"
                    v-if="!prod.saved"
                  >
                    Verwijderen
                  </button>
                </td>
              </tr>
              <tr class="tableRow">
                <td>
                  <select name="productName" v-model="productName">
                    <option v-for="p in producten" :key="p.id" :value="p.id">
                      {{ p.id }}
                    </option>
                  </select>
                </td>
                <td>
                  <input type="number" v-model="adStock" />
                </td>
                <td>
                  <input type="number" v-model="fyStock" />
                </td>
                <td>
                  <button class="btn" @click="addProduct">Toevoegen</button>
                </td>
              </tr>
            </table>
            <!-- <button @click="check">Voorraad Bijwerken</button> -->
            <button class="btn" @click="saveStock" v-if="showButton">
              Voorraad Bijwerken
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useLoadMagazijn, useLoadStock } from "../fb";
import MagazijnLog from "./MagazijnLog.vue";
import MagazijnEdit from "./MagazijnEdit.vue";
import MagazijnTransfer from "./MagazijnTransfer.vue";

export default {
  setup() {
    let producten = useLoadStock();
    let magazijnen = useLoadMagazijn();
    return { producten, magazijnen };
  },
  components: { MagazijnEdit, MagazijnLog, MagazijnTransfer },
  // props: ["magazijn"],
  data() {
    return {
      showLog: false,
      show: false,
      edit: false,
      transfer: false,
      view: false,
      adding: false,
      selectedMagazijn: "",
      adStock: 0,
      fyStock: 0,
      productName: "",
      showButton: false,
    };
  },
  methods: {
    set3Fase() {
      this.$store.dispatch("updateMagazijn", this.selectedMagazijn);
    },
    reRoute() {
      this.$router.push("/magazijn");
    },
    toggleTransfer() {
      this.show = true;
      this.transfer = true;
    },
    update(i, p) {
      this.selectedMagazijn.stock.splice(i, 1);
      this.selectedMagazijn.stock.push(p);
      this.showButton = true;
    },


    saveStock() {
      this.selectedMagazijn.stock.forEach((val) => {
        val.saved = true;
        val.bijgewerkt = false;
      });
      this.$store.dispatch("saveStock", {
        id: this.selectedMagazijn.id,
        content: this.selectedMagazijn.stock,
      });
    },
    addProduct() {
      if (!this.selectedMagazijn.stock) {
        this.selectedMagazijn.stock = [];
      }
      if ((this.adStock, this.fyStock, this.productName)) {
        const obj = {
          productNaam: this.productName,
          adminStock: this.adStock,
          fysiekStock: this.fyStock,
          saved: false,
        };
        this.selectedMagazijn.stock.push(obj);
        this.productName = "";
        this.adStock = 0;
        this.fyStock = 0;
        this.showButton = true;
      }
    },
    computePhone(val) {
      let nr = val;
      nr = nr.substring(1);
      return `tel:+31${nr}`;
    },
    remove(i) {
      this.selectedMagazijn.stock.splice(i, 1);

      if (this.selectedMagazijn.stock.length === 0) {
        this.showButton = false;
      }
    },
  },

  async created() {
    setTimeout(() => {
      this.selectedMagazijn = this.magazijnen.find(
        (val) => val.id === this.$route.params.id
      );
    }, 500);
  },
};
</script>

<style scoped>
.rootDiv {
  padding: 1rem;
  background-color: #fff;
}

.transfer {
  background-color: #fff;
  border: 2px solid #44bace;
  padding: 1rem 2rem;
  color: #44bace;
  border-radius: 12px;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
}

.transfer:hover {
  background-color: #44bace;
  border: 2px solid #44bace;
  color: white;
}

.logIcon {
  float: right;
}

.tableRow {
  background-color: lightgreen;
  opacity: 80%;
}

.productTable {
  padding: 1rem;
  width: auto;
}

.content {
  padding: 2rem;
  border: 1px solid grey;
  border-radius: 12px;
  margin: 2rem 0;
}

._content {
  width: 100%;
  display: flex;
}

.noContent {
  text-align: center;
  padding: 2rem;
  margin: auto;
  border: 1px solid grey;
  border-radius: 12px;
}

.icon {
  cursor: pointer;
  padding: 0.5rem;
  border: 3px solid #44bace;
  background-color: #fff;
  border-radius: 50%;
  color: #44bace;
  float: right;
  transition: 0.3s all ease-in-out;
}

.icon:hover {
  background-color: #44bace;
  color: white;
}

.icon + .icon {
  margin-right: 1rem;
}

.leftSide {
  width: 20%;
}

.rightSide {
  width: 80%;
}

.alert {
  color: black;
  border: 2px solid orange;
  box-shadow: rgba(241, 194, 4, 0.8) 0px 3px 8px;
}

.active {
  color: black;
  border: 2px solid green;
  box-shadow: rgba(82, 245, 39, 0.8) 0px 3px 8px;
  transition: 0.3s all ease-in-out;
}

.inactive {
  color: black;
  border: 2px solid red;
  box-shadow: rgba(255, 0, 0, 0.8) 0px 3px 8px;
  transition: 0.3s all ease-in-out;
}

.inactive:hover {
  box-shadow: rgba(255, 0, 0, 0.8) 8px 8px 15px;
}

.btn {
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 12px;
  background-color: #44bace;
  cursor: pointer;
  color: #fff;
}

table {
  margin: auto;
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: auto;
  margin-bottom: 2rem;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

input,
select {
  padding: 0.5rem;
  border: 1px solid grey;

  border-radius: 12px;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
}

.dialogView {
  position: absolute;
  margin: auto;
  left: 60%;
  right: auto;
  top: 0;
  z-index: 15;
}

dialog {
  width: 40rem;
  height: 60rem;
  /* z-index: 100; */
  border: none;
  background-color: transparent;
}

.dialog-enter-from,
.dialog-leave-to {
  opacity: 0;
  transform: scale(0.8);
}

.dialog-enter-active {
  transition: all 0.3s ease-out;
}

.dialog-leave-active {
  transition: all 0.3 ease-in;
}

.dialog-enter-to,
.dialog-leave-from {
  opacity: 1;
  transform: scale(1);
}

@media (min-width: 768px) {
  dialog {
    left: calc(50% - 30rem);
    width: 60rem;
  }
}


.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}


.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
