<template>
  <div class="rootDiv">
    <div class="clock">
      <h2 class="week">Week: {{ weekNr }}</h2>
      {{ clock }}
    </div>
    <div class="grid">
      <div class="card" style="border: red 3px solid" v-if="blockedVerkoop.length > 0">
        <h3>Geblokkeerde installaties ({{ blockedVerkoop.length }})</h3>
        <p>Deze installaties kunnen niet doorgaan omdat er producten niet leverbaar zijn, of in backorder staan met een leveringsdatum NA de installatiedatum.</p>
        <p
          @click="reRoute(`verkoop/${s.id}`)"
          class="link"
          v-for="s in blockedVerkoop"
          :key="s.id"
        >
          {{ s.naam }} (klantnummer: {{ s.klantNummer }}). Installatiedatum op
          {{ getDate(s.installatieDatum) }}
        </p>
      </div>
      <div class="card" v-if="backOrderVerkoop.length > 0">
        <h3>
          Installaties met mogelijke items in backorder ({{
            backOrderVerkoop.length
          }})
        </h3>
        <p>
          Deze installaties bevatten mogelijk producten die in backorder staan.
        </p>
        <p
          class="link"
          @click="reRoute(`verkoop/${v.id}`)"
          v-for="v in backOrderVerkoop"
          :key="v.id"
        >
          {{ v.naam }} (klantnummer: {{ v.klantNummer }}). Installatiedatum op
          {{ getDate(v.installatieDatum) }}
        </p>
      </div>
      <div class="card" v-if="errorStocks.length > 0">
        <h3>Producten ({{ errorStocks.length }})</h3>
        <p>Prijzen ontbreken bij deze producten</p>
        <p
          @click="reRoute(`producten/${s.id}`)"
          class="link"
          v-for="s in errorStocks"
          :key="s.id"
        >
          {{ s.id }}
        </p>
      </div>
      <div class="card" v-if="mutatieVerkoop.length > 0">
        <h3>Mutatie Installatie ({{ mutatieVerkoop.length }})</h3>
        <p>
          Deze verkopen hebben een andere inhoud nadat er een reservering is
          gedaan.
        </p>
        <p
          class="link"
          @click="reRoute(`verkoop/${v.id}`)"
          v-for="v in mutatieVerkoop"
          :key="v.id"
        >
          {{ v.naam }} (klantnummer: {{ v.klantNummer }}): heeft een
          installatiedatum op {{ getDate(v.installatieDatum) }}
        </p>
      </div>
      <div class="card" v-if="dubbelVerkoop.length > 0">
        <h3>Dubbele Installaties ({{ dubbelVerkoop.length / 2 }})</h3>
        <p>
          Deze verkopen hebben hetzelfde klantnummer, maar andere
          installatiedatum
        </p>
        <p
          class="link"
          @click="reRoute(`verkoop/${v.id}`)"
          v-for="v in dubbelVerkoop"
          :key="v.id"
        >
          {{ v.naam }} (klantnummer: {{ v.klantNummer }}): heeft een
          installatiedatum op {{ getDate(v.installatieDatum) }}
        </p>
      </div>
      <div class="card" v-if="geenMagazijnVerkoop.length > 0">
        <h3>Installaties zonder magazijn ({{ geenMagazijnVerkoop.length }})</h3>
        <p>Deze verkooprecords hebben geen magazijn toegewezen:</p>
        <p
          class="link"
          @click="reRoute(`verkoop/${v.id}`)"
          v-for="v in geenMagazijnVerkoop"
          :key="v.id"
        >
          {{ v.naam }} (klantnummer: {{ v.klantNummer }}). Installatiedatum op
          {{ getDate(v.installatieDatum) }}
        </p>
      </div>
      <div class="card" v-if="negatieveStockMagazijn.length > 0">
        <h3>Magazijnen ({{ negatieveStockMagazijn.length }})</h3>
        <p>Voor deze magazijnen moet nog besteld worden:</p>
        <p
          class="link"
          @click="reRoute(`magazijn/${v.id}`)"
          v-for="v in negatieveStockMagazijn"
          :key="v.id"
        >
          {{ v.bedrijf }}
        </p>
      </div>

      <div class="card" v-if="onvolledigeDataMagazijn.length > 0">
        <h3>
          Magazijnen incomplete data ({{ onvolledigeDataMagazijn.length }})
        </h3>
        <p>
          Voor deze magazijnen moet nog info bijgewerkt worden. Let op! Deze
          staan nu op <i class="inactief">INACTIEF</i> :
        </p>
        <p
          class="link"
          @click="reRoute(`magazijn/${v.id}`)"
          v-for="v in onvolledigeDataMagazijn"
          :key="v.id"
        >
          {{ v.bedrijf }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { useLoadMagazijn, useLoadStock, useLoadVerkoop } from "../fb.js";
import moment from "moment";
export default {
  setup() {
    let stocks = useLoadStock();
    let verkoop = useLoadVerkoop();
    let magazijnen = useLoadMagazijn();
    return { stocks, verkoop, magazijnen };
  },
  data() {
    return {
      clock: "",
    };
  },
  methods: {
    reRoute(path) {
      this.$router.push(path);
    },
    getDate(date) {
      date = +date;
      return this.moment(date).format("DD-MM-YYYY");
    },
    startTime() {
      const today = new Date();
      let datum = this.getDate(today.valueOf());
      let h = today.getHours();
      let m = today.getMinutes();
      let s = today.getSeconds();
      m = this.checkTime(m);
      s = this.checkTime(s);
      this.clock = datum + " " + h + ":" + m + ":" + s;
      setTimeout(this.startTime, 1000);
    },
    checkTime(i) {
      if (i < 10) {
        i = "0" + i;
      } // add zero in front of numbers < 10
      return i;
    },
  },
  computed: {
    dubbelVerkoop() {
      let object = {};
      let array = [];
      this.verkoop.forEach((_verkoop) => {
        if (!_verkoop.verwijderd) {
          if (object[_verkoop.klantNummer]) {
            array.push(_verkoop);
            array.push(
              this.verkoop.find(
                (val) =>
                  val.klantNummer === _verkoop.klantNummer &&
                  val.installatieDatum !== _verkoop.installatieDatum
              )
            );
          } else {
            object[_verkoop.klantNummer] = _verkoop.klantNummer;
          }
        }
      });
      return array;
    },
    weekNr() {
      let currentDate = new Date();
      let startDate = new Date(currentDate.getFullYear(), 0, 1);
      var days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));

      var weekNumber = Math.ceil(days / 7);
      return weekNumber;
    },

    errorStocks() {
      const array = [];
      this.stocks.forEach((_stocks) => {
        if (_stocks.prijzen.length === 0 || !_stocks.prijzen) {
          array.push(_stocks);
        }
      });
      return array;
    },

    mutatieVerkoop() {
      const array = [];
      this.verkoop.forEach((_verkoop) => {
        if (_verkoop.mutatie) {
          array.push(_verkoop);
        }
      });
      return array;
    },
    geenMagazijnVerkoop() {
      const array = [];
      this.verkoop.forEach((_verkoop) => {
        if (!_verkoop.magazijn) {
          array.push(_verkoop);
        }
      });
      return array;
    },
    negatieveStockMagazijn() {
      const array = [];
      this.magazijnen.forEach((_magazijnen) => {
        if (_magazijnen.stock) {
          if (_magazijnen.showAlert && _magazijnen.stock.length > 0) {
            array.push(_magazijnen);
          }
        }
      });
      return array;
    },
    onvolledigeDataMagazijn() {
      const array = [];
      this.magazijnen.forEach((_magazijnen) => {
        if (!_magazijnen.adres || !_magazijnen.postCode) {
          array.push(_magazijnen);
        }
      });
      return array;
    },
    backOrderVerkoop() {
      const array = [];
      this.verkoop.forEach((_verkoop) => {
        if (_verkoop.backOrderItems) {
          if (_verkoop.backOrderItems.length > 0) {
            array.push(_verkoop);
          }
        }
      });
      return array;
    },
    blockedVerkoop() {
      const array = [];
      this.verkoop.forEach((_verkoop) => {
        if (_verkoop.backOrderItems) {
          if (_verkoop.backOrderItems.length > 0) {
            const back = _verkoop.backOrderItems.find(
              (_item) => (_item.status === "Backorder" && new Date(_item.levertijd).valueOf() > _verkoop.installatieDatum) || _item.status === "Niet leverbaar"
            );
            if (back) {
              array.push(_verkoop);
            }
          }
        }
      });
      return array
    },
  },
  created() {
    this.moment = moment;
    this.startTime();
  },
};
</script>

<style scoped>
p {
  font-size: 0.8rem;
}

.inactief {
  background-color: darkred;
  color: #fff;
  padding: 0.1rem 0.5rem;
  border-radius: 12px;
}

.card {
  height: 15rem;
  overflow: auto;
  border-radius: 12px;
  border: 1px solid grey;
  background-color: #fff;
  padding: 1rem;
  margin: 1rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.grid {
  display: grid;
  grid-template-columns: 33% 33% 33%;
}

.clock {
  margin-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: right;
}

.week {
  margin-right: 1rem;
}

.link {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}
</style>
