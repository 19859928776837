<template>
  <!-- <button @click="check">check</button> -->
  <div v-if="showAlert" style="color: red">
    <p>Let op! Er zijn producten in de lijst die nog geen prijzen hebben!</p>
    <p
      class="errorProduct"
      @click="reRoute(e)"
      v-for="e in errorProducts"
      :key="e"
    >
      {{ e }}
    </p>
  </div>
  <div v-if="showNietLeverbaar" style="color: red">
    <p>Let op! Er zijn producten in de lijst die niet leverbaar zijn!</p>
    <p
      class="errorProduct"
      @click="reRoute(e)"
      v-for="e in nietLeverbaarProducts"
      :key="e"
    >
      {{ e }}
    </p>
  </div>
  <table v-if="stock">
    <h3>Benodigde items:</h3>
    <tr>
      <th>Naam</th>
      <th>Artikelnummer</th>
      <th>Prijs Ex BTW</th>
      <th>Aantal</th>
      <th>Eenheid</th>
      <th>Toegevoegd</th>
      <th>Admin Waarde in Magazijn</th>
      <th>Fysieke Waarde in Magazijn</th>
    </tr>
    <tr v-for="s in stock" :key="s.id">
      <td style="font-size: small">
        {{ (s.selectedName = getPrice(s, 2) || s.id) }}
      </td>
      <td style="font-size: small">
        {{ (s.artikelNummer = getArtikelNummer(s) || "") }}
      </td>
      <td>€{{ (s.selectedPrice = getPrice(s, 0)) }}</td>
      <td>
        {{ (s.hoeveelheid = getAmount(s) || 0) }}
      </td>
      <td style="text-align: center">
        x {{ (s.verpakkingsEenheid = getPrice(s, 1)) }}
      </td>

      <td>{{ (s.toegevoegd = s.hoeveelheid * s.verpakkingsEenheid) }}</td>
      <td v-if="!s.zonnepaneel">
        {{ (s.magStockAdmin = magazijnAdminStock(s.id) || 0) }}
      </td>
      <td v-if="!s.zonnepaneel">
        {{ (s.magStockFysiek = magazijnFysiekStock(s.id) || 0) }}
      </td>
      <td v-if="s.zonnepaneel">
        {{ (s.magStockAdmin = magazijnAdminStockZon(s) || 0) }}
      </td>
      <td v-if="s.zonnepaneel">
        {{ (s.magStockFysiek = magazijnFysiekStockZon(s) || 0) }}
      </td>
    </tr>
    <!-- <button class="btn" @click="addInkoop(_, 1)">Alles Toevoegen</button> -->
    <div class="wrapper">
      <p class="left">Factuurbedrag:</p>
      <p>€{{ formatFactuurBedrag }}</p>
    </div>
  </table>

  <h3>Aanvullende items:</h3>
  <select v-model="product">
    <option v-for="s in st" :key="s" :value="s">{{ s.id }}</option>
  </select>
  <select v-if="product" v-model="subProd">
    <option v-for="sub in product.prijzen" :key="sub" :value="sub">
      {{ sub.artikelNaam }}
    </option>
  </select>
  <button class="btn" v-if="subProd" @click="addProduct">Toevoegen</button>

  <table v-if="extraStock.length > 0">
    <tr>
      <th>Naam</th>
      <th>Artikelnummer</th>
      <th>Prijs Ex BTW</th>
      <th>Aantal</th>
      <th>Eenheid</th>
      <th>Toegevoegd</th>
      <th>Admin Waarde in Magazijn</th>
      <th>Fysieke Waarde in Magazijn</th>
    </tr>
    <tr v-for="s in extraStock" :key="s.id">
      <td style="font-size: small">
        {{ s.selectedName }}
        <!-- {{ (s.selectedName = getPrice(s, 2) || s.id) }} -->
      </td>
      <td style="font-size: small">
        {{ s.artikelNummer }}
      </td>
      <td>€ {{ s.selectedPrice }}</td>
      <td v-if="!s.toegevoegd">
        <input type="number" v-model="s.hoeveelheid" />
      </td>
      <td v-else>{{ s.hoeveelheid }}</td>
      <td style="text-align: center">x {{ s.verpakkingsEenheid }}</td>

      <td v-if="!s.toegevoegd && s.hoeveelheid">
        <button
          class="btn"
          style="margin: auto"
          @click="s.toegevoegd = s.hoeveelheid"
        >
          toevoegen
        </button>
      </td>
      <td v-if="s.toegevoegd">{{ s.toegevoegd }}</td>
      <td v-if="!s.zonnepaneel">
        {{ (s.magStockAdmin = magazijnAdminStock(s.id) || 0) }}
      </td>
      <td v-if="!s.zonnepaneel">
        {{ (s.magStockFysiek = magazijnFysiekStock(s.id) || 0) }}
      </td>
      <td v-if="s.zonnepaneel">
        {{ (s.magStockAdmin = magazijnAdminStockZon(s) || 0) }}
      </td>
      <td v-if="s.zonnepaneel">
        {{ (s.magStockFysiek = magazijnFysiekStockZon(s) || 0) }}
      </td>
    </tr>
  </table>

  <div v-if="stock">
    <p class="left">Opmerking:</p>
    <textarea v-model="opmerking" cols="100" rows="10"></textarea>
  </div>
  <button v-if="stock" class="btn" @click="createInkoop">Opslaan</button>
</template>

<script>
import { useLoadMagazijn, useLoadStock } from "../fb.js";

export default {
  emits: ["close"],
  data() {
    return {
      product: "",
      subProd: "",
      hoeveelheid: 0,
      bestelItems: [],
      factuurBedrag: 0,
      opmerking: "",
      stock: "",
      selectedZonnepaneel: "",
      selectedArtikelNummer: "",
      showAlert: false,
      errorProducts: [],
      nietLeverbaarProducts: [],
      showNietLeverbaar: false,
      extraStock: [],
    };
  },
  setup() {
    let st = useLoadStock();
    let magazijnen = useLoadMagazijn();
    return { st, magazijnen };
  },
  props: ["leverancier", "initialVal"],
  computed: {
    formatFactuurBedrag() {
      return this.factuurBedrag.toFixed(2);
    },
    initValMagazijn() {
      const mag = this.magazijnen.filter(
        (_magazijnen) => _magazijnen.id === this.initialVal.id
      );
      return mag[0];
    },
    afleverLocatie() {
      return this.initialVal.bedrijf;
    },
  },
  methods: {
    addProduct() {
      const doc = {
        artikelNummer: this.subProd.artikelNummer,
        hoeveelheid: 0,
        id: this.product.id,
        selectedName: this.subProd.artikelNaam,
        verpakkingsEenheid: this.subProd.verpakkingsEenheid,
        selectedPrice: this.subProd.prijsExBtw,
        zonnepaneel: this.product.zonnepaneel,
      };
      this.extraStock.push(doc);
    },
    reRoute(id) {
      // let idFormat = id.replaceAll(" ", "_");
      this.$router.push(`producten/${id}`);
    },
    getPrice(stock, type) {
      let returnVal = 0;
      if (type === 1) {
        returnVal = 1;
      }
      stock.prijzen.forEach((val) => {
        if (val.naam === this.leverancier) {
          if (type === 0) {
            returnVal = val.prijsExBtw;
          } else if (type === 1) {
            if (val.verpakkingsEenheid) {
              returnVal = val.verpakkingsEenheid;
            }
          }
        }
      });
      return returnVal;
    },
    getArtikelNummer(stock) {
      let returnVal = "";
      // const d = new Date().valueOf();
      stock.prijzen.forEach((val) => {
        if (val.naam === this.leverancier) {
          returnVal = val.artikelNummer;
        }
      });
      return returnVal;
    },

    getAmount(val) {
      let stockVal = 0;

      // if (this.selectedMagazijn.stock) {
      this.selectedMagazijn.stock.forEach((value) => {
        if (value.productNaam === val.id && value.adminStock < 0) {
          if (val.verpakkingsEenheid === 1 || !val.verpakkingsEenheid) {
            stockVal = value.adminStock * -1;
            return stockVal;
          } else {
            stockVal = Math.ceil(
              (value.adminStock * -1) / val.verpakkingsEenheid
            );
            return stockVal;
          }
        }
      });
      return stockVal;
      // }
    },
    getAmountZon(val) {
      let stockVal = 0;
      let amount = 0;
      let amountValue = 0;
      const genStock = this.selectedMagazijn.stock.find(
        (value) => value.productNaam === val.id
      );
      const specStock = this.selectedMagazijn.stock.find(
        (value) => value.productNaam === val.selectedName
      );
      if (genStock) {
        amount = genStock.adminStock;
        amountValue = amount;
      }
      if (specStock) {
        amountValue = specStock.adminStock + amount;
      }
      if (amountValue > 0) {
        return stockVal;
      } else {
        if (val.verpakkingsEenheid === 1 || !val.verpakkingsEenheid) {
          stockVal = amountValue * -1;
          return stockVal;
        } else {
          stockVal =
            Math.ceil((amountValue * -1) / val.verpakkingsEenheid) *
            val.verpakkingsEenheid;
          return stockVal;
        }
      }
    },
    magazijnAdminStock(val) {
      let stockVal = "";
      if (this.selectedMagazijn.stock) {
        this.selectedMagazijn.stock.forEach((value) => {
          if (value.productNaam === val) {
            stockVal = value.adminStock;
          }
        });
        return stockVal;
      }
    },
    magazijnAdminStockZon(val) {
      let stockVal = "";
      let amount = 0;
      if (!this.selectedMagazijn.stock) {
        this.selectedMagazijn.stock = [];
      }
      const genStock = this.selectedMagazijn.stock.find(
        (value) => value.productNaam === val.id
      );
      const specStock = this.selectedMagazijn.stock.find(
        (value) => value.productNaam === val.selectedName
      );
      if (genStock) {
        amount = genStock.adminStock;
        stockVal = amount;
      } else if (specStock) {
        amount = specStock.adminStock;
        stockVal = specStock.adminStock + amount;
      }
      return stockVal;
    },
    magazijnFysiekStock(val) {
      let stockVal = "";
      if (this.selectedMagazijn.stock) {
        this.selectedMagazijn.stock.forEach((value) => {
          if (value.productNaam === val) {
            stockVal = value.fysiekStock;
          }
        });
        return stockVal;
      }
    },
    magazijnFysiekStockZon(val) {
      let stockVal = "";
      let amount = 0;
      const genStock = this.selectedMagazijn.stock.find(
        (value) => value.productNaam === val.id
      );
      const specStock = this.selectedMagazijn.stock.find(
        (value) => value.productNaam === val.selectedName
      );
      if (genStock) {
        amount = genStock.fysiekStock;
        stockVal = amount;
      }
      if (specStock) {
        stockVal = specStock.fysiekStock + amount;
      }
      return stockVal;
    },
    addInkoop(stock, type = 0) {
      stock.forEach((prod) => {
        if (prod.hoeveelheid > 0) {
          let naam = prod.id;

          if (type === 1) {
            let double = this.bestelItems.find(
              (_items) => _items.naam === naam
            );
            let doubleIndex = this.bestelItems.findIndex(
              (_it) => _it.naam === naam
            );
            if (double) {
              const d = {
                artikelNummer: prod.artikelNummer,
                aantal: prod.toegevoegd + double.aantal,
                naam: naam,
                geleverd: false,
                status: "",
                adminStock: prod.toegevoegd,
              };
              this.bestelItems.splice(doubleIndex, 1);
              this.bestelItems.push(d);
            } else {
              const doc = {
                artikelNummer: prod.artikelNummer,
                aantal: prod.toegevoegd,
                naam: naam,
                geleverd: false,
                status: "",
                adminStock: prod.toegevoegd,
              };
              this.bestelItems.push(doc);
            }
          } else {
             const doc = {
                artikelNummer: prod.artikelNummer,
                aantal: prod.toegevoegd,
                naam: naam,
                geleverd: false,
                status: "",
                adminStock: prod.toegevoegd,
              };
              this.bestelItems.push(doc);
          }
        }
        this.factuurBedrag =
          this.factuurBedrag + prod.toegevoegd * prod.selectedPrice;
        const selectedStock = this.stockNaarMagazijn.find(
          (val) => prod.id === val.productNaam
        );
        if (selectedStock) {
          selectedStock.adminStock = selectedStock.adminStock + prod.toegevoegd;
        } else {
          let naam = prod.id;

          const doc = {
            fysiekStock: 0,
            productNaam: naam,
            saved: true,
            bijgewerkt: false,
            adminStock: prod.toegevoegd,
          };
          this.stockNaarMagazijn.push(doc);
        }
      });
    },

    async createInkoop() {
      
      await this.addInkoop(this.stock, 0);

      if (this.extraStock) {
        await this.addInkoop(this.extraStock, 1);
      }
      this.factuurBedrag = this.factuurBedrag.toFixed(2);
      this.factuurBedrag = +this.factuurBedrag;

      const d = new Date().valueOf();
      const doc = {
        opmerking: this.opmerking,
        afleverLocatie: this.afleverLocatie,
        bestelDatum: d,
        bestelItems: this.bestelItems,
        bestelnummer: d,
        factuurBedrag: this.factuurBedrag,
        id: d,
        leverancier: this.leverancier,
        verwerkt: false,
        volledigGeleverd: false,
      };

      const log = {
        afterValue: doc,
        collection: "Inkoop",
        recordId: doc.bestelDatum.toString(),
        type: "create",
        timeStamp: doc.bestelDatum,
      };

      const doc2 = {
        id: this.selectedMagazijn.id,
        content: this.stockNaarMagazijn,
      };

      this.selectedMagazijn.stock = this.stockNaarMagazijn;
      let editedValue = this.selectedMagazijn;
      editedValue.stock = this.stockNaarMagazijn;
      const log2 = {
        afterValue: this.selectedMagazijn,
        collection: "Magazijnen",
        recordId: this.initValMagazijn.id,
        type: "administratief",
        timeStamp: doc.bestelDatum,
      };


      const da = doc.bestelDatum.toString();
      await this.$store.dispatch("createInkoop", doc);
      await this.$store.dispatch("setVerkoopToBesteld", {
        magazijn: this.selectedMagazijn.bedrijf,
        id: da,
      });
      await this.$store.dispatch("createLog", log);
      await this.$store.dispatch("saveStock", doc2);
      await this.$store.dispatch("createLog", log2);
      this.$emit("close");
    },

    removeInkoop(prod, i) {
      this.bestelItems.splice(i, 1);
      this.stockNaarMagazijn.forEach((value) => {
        if (prod.id === value.productNaam) {
          return (value.adminStock = value.adminStock - prod.hoeveelheid);
        }
      });
      this.factuurBedrag =
        this.factuurBedrag - +prod.hoeveelheid * +prod["Prijs ex"];
    },
  },
  created() {
    setTimeout(() => {
      const s = [];
      this.initialVal.stock.forEach((_stock) => {
        if (_stock.adminStock !== 0 || _stock.fysiekStock > 0) {
          let rec = null;

          rec = this.st.find((val) => val.id === _stock.productNaam);

          if (rec) {
            if (rec.prijzen.length > 0) {
              rec.prijzen.forEach((_prijs) => {
                if (_prijs.default === true && _prijs.leverbaar) {
                  s.push(rec);
                } else {
                  if (!_prijs.leverbaar) {
                    this.nietLeverbaarProducts.push(rec.id);
                    this.showNietLeverbaar = true;
                  }
                }
              });
            } else {
              this.showAlert = true;
              this.errorProducts.push(rec.id);
            }
          }
        }
      });
      this.stock = s;
    }, 1500);
    this.selectedMagazijn = this.initialVal;
    if (this.selectedMagazijn.stock) {
      this.stockNaarMagazijn = this.selectedMagazijn.stock;
    } else {
      this.stockNaarMagazijn = [];
    }
  },
};
</script>

<style scoped>
.btn {
  cursor: pointer;
  margin-top: 2rem;
  border: none;
  padding: 0.5rem 1rem;
  color: #fff;
  background-color: #44bace;
  border-radius: 12px;
  border: 2px solid transparent;
  transition: 0.3s all ease-in-out;
}

.btn:hover {
  border: 2px solid #358391;
}

input,
select {
  width: 60%;
  border: 1px solid #cccccc;
  padding: 1rem 1rem 1rem;
  border-radius: 0.4rem;
  background-color: #fff;
  transition: 1.5s all ease-in-out;
}

input:focus {
  width: 60%;
  border: 1px solid #44bace;
  padding: 1rem 1rem 1rem;
  border-radius: 0.4rem;
  background-color: lightgrey;
}

table {
  margin: auto;
  margin-top: 1rem;
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 2rem;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

.wrapper {
  display: flex;
}

.left {
  width: 60%;
  margin-right: 1rem;
}

.errorProduct {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 12px;
  transition: all 0.3s ease-in-out;
}

.errorProduct:hover {
  border: 1px solid blue;
  border-radius: 12px;
}
</style>
