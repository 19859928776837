import { createApp } from 'vue';
import App from './App.vue';
import store from "./store/index.js";
import router from "./router.js";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import { faPowerOff, faArrowLeft, faMinus, faFileSignature, faCopy } from "@fortawesome/free-solid-svg-icons";
import { faInfo } from "@fortawesome/free-solid-svg-icons";

import {
  FontAwesomeIcon,
  FontAwesomeLayers,
  FontAwesomeLayersText,
} from "@fortawesome/vue-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
library.add(faPen);
library.add(faPlus);
library.add(faPowerOff);
library.add(faArrowLeft);
library.add(faInfo);
library.add(faMinus);
library.add(faFileSignature);
library.add(faCopy);
const app = createApp(App);
app.use(store);
app.use(router);
app.component("font-awesome-icon", FontAwesomeIcon);
app.component("font-awesome-layers", FontAwesomeLayers);
app.component("font-awesome-layers-text", FontAwesomeLayersText);
app.mount('#app')
