<template>
  <div class="rootDiv">
    <div class="description">
      <div class="genContent">
        <font-awesome-icon
          class="icon arrow"
          icon="arrow-left"
          @click="reRoute()"
        />

        <h2>Product naam: {{ product.id }}
                  <font-awesome-icon
            style="margin-right: 3rem"
            class="icon"
            icon="copy"
            @click="artikelNaam = product.id"
          />
        </h2>
        <h1 v-if="showError">
          Dit product heeft geen standaard product toegekend.
        </h1>
        <p v-if="typeText === 'Zonnepaneel'">Type {{typeText}}: {{product.typeZonnepaneel}}</p>
        <p v-if="typeText === 'Zonnepaneel'">WattPiek: {{product.wp}}</p>

        <p v-if="!edit">
          Type Groei: {{ product.typeHoofdProduct }}
          <font-awesome-icon
            style="margin-right: 3rem"
            class="icon"
            icon="pen"
            @click="edit = true"
          />
        </p>
        <p v-if="product.growth">
          Groei: 1 per {{ product.growth }} {{ aantalFormat }}
        </p>
        <div class="wrapperField" v-if="edit">
          <p style="width: 20%">Type Product:</p>
          <select style="width: 60%" type="text" v-model="typeHoofdProduct">
            <option default value="">selecteer type</option>
            <option value="absoluut">
              Absoluut (aantal is altijd hetzelfde)
            </option>
            <option value="variabel">
              Variabel (aantal groeit mee met bepaald product)
            </option>
          </select>
          <p v-if="typeHoofdProduct === 'variabel'">
            Groei per zonnepaneel:
            <input class="inputAantal" type="number" v-model="growth" />
            <button
              @click="updateField(product.id), edit = false"
              v-if="growth"
              class="btnOpslaan"
            >
              Opslaan
            </button>
          </p>
        </div>
      </div>
      <div class="section">
        <h3>Producten</h3>
        <table>
          <tr>
            <th style="width: 10%">Artikelnaam</th>
            <th style="width: 5%">Leverancier</th>
            <th style="width: 5%">Verpakkings eenheid</th>
            <th style="width: 5%">Artikelnummer</th>
            <th style="width: 5%">Prijs Ex BTW</th>
            <th style="width: 5%">Prijs Startdatum</th>
            <th style="width: 5%">Prijs Einddatum</th>
            <th style="width: 5%">Korting</th>
            <th style="width: 5%">Korting van</th>
            <th style="width: 5%">Korting tot</th>
            <th style="width: 5%">Standaard Product</th>
            <th style="width: 5%">Toevoegen</th>
            <th style="width: 5%">Leverbaar?</th>
          </tr>
          <tr v-for="p of product.prijzen" :key="p.prijsExBtw">
            <td>{{ p.artikelNaam }}</td>
            <td>{{ p.naam }}</td>
            <td>{{ p.verpakkingsEenheid }}</td>
            <td>{{ p.artikelNummer }}</td>
            <td>{{ prijsFormat(p.prijsExBtw) }}</td>
            <td>{{ getDate(p.prijsVan) }}</td>
            <td>{{ getDate(p.prijsTot) }}</td>
            <td v-if="p.korting">{{ p.korting }}%</td>
            <td v-else>Geen Korting</td>
            <td v-if="p.kortingVan">{{ getDate(p.kortingVan) }}</td>
            <td v-else>Niet van toepassing</td>
            <td v-if="p.kortingTot">{{ getDate(p.kortingTot) }}</td>
            <td v-else>Niet van toepassing</td>
            <td v-if="!p.default">
              <button @click="setDefault(p)">Gebruik product</button>
            </td>
            <td v-else>Standaard Product</td>
            <td v-if="!p.showBevestigen"></td>
            <td v-if="p.showBevestigen">
              <button @click="update(p)">Bevestig</button>
            </td>
            <td
              style="text-decoration: underline; color: blue; cursor: pointer"
              @click="p.editLeverbaar = true"
              v-if="p.leverbaar && !p.editLeverbaar"
            >
              Ja
            </td>
            <td
              @click="p.editLeverbaar = true"
              style="background-color: darkred; color: white; cursor: pointer"
              v-if="!p.leverbaar && !p.editLeverbaar"
            >
              Nee
            </td>
            <td v-if="p.editLeverbaar">
              <select v-model="p.leverbaar" @change="p.showBevestigen = true">
                <option :value="true">Ja</option>
                <option :value="false">Nee</option>
              </select>
            </td>
          </tr>
          <!-- <h3>Toevoegen Prijs</h3> -->
          <tr style="vertical-align: top">
            <td>
              <input type="text" v-model="artikelNaam" />
            </td>
            <td>
              <select v-model="leverancier">
                <option default value="">Kies je leverancier</option>
                <option v-for="l in leveranciers" :key="l.id" :value="l.naam">
                  {{ l.naam }}
                </option>
              </select>
              <small>
                <i>Kies de leverancier</i>
              </small>
            </td>
            <td>
              <input type="number" v-model="verpakkingsEenheid" />
              <small>
                <i>Voer hier de verpakkings eenheid in</i>
              </small>
            </td>
            <td>
              <input
                placeholder="Het artikelnummer"
                type="text"
                v-model="artikelNummer"
              />
              <small>
                <i>Voer hier het artikelnummer in</i>
              </small>
            </td>
            <td>
              <input type="number" v-model="prijsEx" />
              <small>
                <i>Voer hier de prijs in</i>
              </small>
            </td>
            <td>
              <input type="date" v-model="prijsVan" />
              <small>
                <i>Ingangsdatum prijs</i>
              </small>
            </td>
            <td>
              <input type="date" v-model="prijsTot" />
              <small>
                <i>Einddatum prijs</i>
              </small>
            </td>
            <td>
              <input type="number" v-model="percentage" />
              <small>
                <i>(indien van toepassing) het kortingspercentage</i>
              </small>
            </td>
            <td>
              <input type="date" v-model="kortVan" />
              <small>
                <i
                  >Ingangsdatum korting (wanneer onbekend, laat je het veld
                  leeg)</i
                >
              </small>
            </td>
            <td>
              <input type="date" v-model="kortTot" />
              <small>
                <i>Eind korting (wanneer onbekend, laat je het veld leeg)</i>
              </small>
            </td>
            <td>
              <select v-model="productDefault">
                <option :value="true">Product standaard gebruiken</option>
                <option :value="false">Product niet standaard gebruiken</option>
              </select>
            </td>
            <td>
              <button @click="productToevoegen()">Toevoegen</button>
            </td>
            <td>Ja</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { useLoadLeveranciers, useLoadStock } from "../fb.js";

export default {
  setup() {
    let leveranciers = useLoadLeveranciers();
    let producten = useLoadStock();
    return { leveranciers, producten };
  },
  props: ["id"],
  data() {
    return {
      productDefault: false,
      show: false,
      showError: false,
      edit: false,
      growth: 0,
      artikelNaam: "",
      leverancier: "",
      prijsEx: "",
      percentage: "",
      prijsVan: "",
      prijsTot: "",
      kortVan: "",
      kortTot: "",
      verpakkingsEenheid: "",
      artikelNummer: "",
      typeHoofdProduct: "",
      subProductType: "",
      subProductNaam: "",
      subProductAantal: "",
      product: "",
    };
  },
  methods: {
    prijsFormat(prijs) {
      let p = prijs.toString();
      if (p.includes(".")) {
        
        p = p.replace(".", ",");
        return `€${p}`
      } else {

        return `€${p},00`;
      }
    },
    update(prod) {
      if (prod.default && !prod.leverbaar) {
        prod.default = false;
      }
      else if (!prod.default && prod.leverbaar) {
        prod.default = true;
      }
        delete prod.editLeverbaar;
        delete prod.showBevestigen;

      this.$store.dispatch("updateProductPrijzen", {
        id: this.product.id,
        content: this.product.prijzen,
      });
    },
    reRoute() {
      this.$router.push("/producten");
    },
    checkForDefault() {
      let p = this.product.prijzen.find((_prijs) => _prijs.default === true);
      if (!p) {
        this.showError = true;
      } else {
        this.showError = false;
      }
    },
    updateField(id) {
      const doc = {
        typeHoofdProduct: this.typeHoofdProduct,
        growth: this.growth,
      };
      this.$store.dispatch("updateStockField", { id: id, content: doc });
    },
    findTypeSubProduct() {
      this.stocks.forEach((val) => {
        if (val.id === this.subProductNaam) {
          this.subProductType = val.typeHoofdProduct;
        }
      });
    },
    setDefault(product) {
      this.product.prijzen.forEach((p) => {
        if (p.naam === product.naam && p.default === true) p.default = false;
      });
      product.default = true;
      this.$store.dispatch("updateProductPrijzen", {
        id: this.product.id,
        content: this.product.prijzen,
      });
      this.checkForDefault();
    },
    productToevoegen() {
      this.productDefault = true;
      const doc = {
        leverbaar: true,
        default: this.productDefault,
        artikelNaam: this.artikelNaam,
        verpakkingsEenheid: this.verpakkingsEenheid,
        artikelNummer: this.artikelNummer,
        naam: this.leverancier,
        prijsExBtw: this.prijsEx,
        korting: this.percentage,
        prijsVan: new Date(this.prijsVan).valueOf() || new Date().valueOf(),
        prijsTot: new Date(this.prijsTot).valueOf() || "nvt",
        kortingVan: new Date(this.kortVan).valueOf() || "nvt",
        kortingTot: new Date(this.kortTot).valueOf() || "nvt",
      };

      let prijzen = this.product.prijzen;
      prijzen.forEach((_prijzen) => {
        if (_prijzen.naam === this.leverancier) {
          _prijzen.prijsTot = doc.prijsVan - 86400000;
          if (this.productDefault === true) {
            _prijzen.default = false;
          }
        }
      });
      prijzen.push(doc);
      this.$store.dispatch("updateProductPrijzen", {
        id: this.product.id,
        content: prijzen,
      });
      this.artikelNaam = "";
      this.prijsVan = "";
      this.prijsTot = "";
      this.leverancier = "";
      this.prijsEx = "";
      this.percentage = "";
      this.kortVan = "";
      this.kortTot = "";
    },

    getDate(date) {
      if (isNaN(date)) {
        return "n.v.t.";
      } else {
        return this.moment(date).format("DD-MM-YYYY");
      }
    },
  },
  computed: {
    typeText() {
      if (this.product.zonnepaneel) {
        return "Zonnepaneel";
      } else if (this.product.omvormer) {
        return "Omvormer";
      } else if (this.product.overig) {
        return "Overig";
      } else {
        return "";
      }
    },
    aantalFormat() {
      if (this.product.growth === 1) {
        return "zonnepaneel";
      } else {
        return "zonnepanelen";
      }
    },
  },
  async created() {
    console.log(this.id);
    setTimeout(() => {
      this.product = this.producten.find((_product) => _product.id === this.id);
    }, 500);
    this.moment = moment;
  },
};
</script>

<style scoped>
.rootDiv {
  padding: 1rem;
}
.inputAantal {
  width: 10% !important;
  margin-top: 1rem;
}
.description {
  border: 1px solid grey;
  border-radius: 12px;
  padding: 5px;
}

.active {
  background-color: #44bace;
  color: #fff;
}

.alert {
  background-color: red;
  color: white;
}

.genContent {
  margin: auto;
  width: 90%;

  text-align: left;
}

.icon {
  border: 3px solid transparent;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  padding: 0.5rem;
  vertical-align: middle;
  /* float: right; */
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  z-index: 10;
}

.icon:hover {
  border: 3px solid #44bace;
  color: white;
  background-color: #44bace;
}

.arrow {
  float: right;
  border: 3px solid #44bace;
  color: #44bace;
}

.section {
  background-color: rgba(128, 128, 128, 0.78);
}

table {
  margin: auto;
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 2rem;
  overflow-y: scroll;
}

td,
th {
  font-size: small;
  border: 1px solid #dddddd;
  text-align: center;
  padding: 8px;
}

th {
  background-color: #44bace;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

select,
input {
  padding: 0.5rem 0.5rem;
  width: 90%;
  border: 1px solid grey;
  border-radius: 12px;
}

small {
  color: darkred;
}

button {
  width: 100%;
  border: none;
  background-color: #44bace;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 12px;
  cursor: pointer;
}

.btnOpslaan {
  display: inline;
  width: 10% !important;
  margin-left: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

h3 {
  margin-left: 2rem;
  padding-top: 1rem;
}
</style>
