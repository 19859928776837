<template>
  <div class="rootDiv">
    <div class="description">
      <div class="genContent">
        <button
          v-if="
            !selectedVerkoop.gereserveerd &&
            selectedVerkoop.magazijn &&
            !double.gereserveerd
          "
          class="btn"
          @click="verwerken()"
        >
          Reserveren
        </button>

        <button v-if="selectedVerkoop.mutatie" class="btn" @click="muteren()">
          Mutatie Accepteren
        </button>
        <button
          v-if="
            selectedVerkoop.gereserveerd &&
            !selectedVerkoop.annulering &&
            !selectedVerkoop.afgerond &&
            !selectedVerkoop.mutatie
          "
          class="btn"
          @click="verwerken(1)"
        >
          Terugzetten op Voorraad
        </button>
        <button
          v-if="
            selectedVerkoop.gereserveerd &&
            !selectedVerkoop.annulering &&
            !selectedVerkoop.afgerond &&
            !selectedVerkoop.mutatie
          "
          class="btn"
          @click="afronden()"
        >
          Afronden
        </button>
        <button
          v-if="
            selectedVerkoop.gereserveerd &&
            !selectedVerkoop.annulering &&
            selectedVerkoop.afgerond &&
            !selectedVerkoop.mutatie
          "
          class="btn"
          @click="afronden(1)"
        >
          Fysieke Voorraad Terugzetten
        </button>

        <button
          v-if="!selectedVerkoop.gereserveerd && !selectedVerkoop.verwijderd"
          class="btn deleteBtn"
          @click="setVerwijderen()"
        >
          Order verwijderen
        </button>
        
        <p v-if="showErrorMessage" style="color: red; font-weight: 700">
          Alle mutatie items moeten een magazijn hebben om door te gaan.
        </p>
        <div class="contentWrapper">
          <div class="leftContent">
            <!-- <p> filter: {{ this.filter }}</p> -->
            <p>Status: {{ verkoopStatus }}</p>
            <p>
              Verkoop binnengekomen op:
              {{ getDateTime(selectedVerkoop.creationDate) }}
            </p>
            <p>Naam: {{ selectedVerkoop.naam }}</p>
            <i class="link" @click="openDialog()">Naar Klantprocesstap</i>
            <p>Klantnummer: {{ selectedVerkoop.klantNummer }}</p>
            <p>Adres: {{ selectedVerkoop.adres }}</p>
            <p>Postcode: {{ selectedVerkoop.postcode }}</p>
          </div>
          <div class="rightContent">
            <p>
              Installatiedatum: {{ getDate(selectedVerkoop.installatieDatum) }}
            </p>
            <p>Installateur: {{ selectedVerkoop.installateur || "" }}</p>
            <p v-if="double">
              Dubbele installatieDatum:
              <i class="link" @click="reRoute(`/verkoop/${double.id}`)"
                >{{ getDate(double.installatieDatum) }} ({{
                  doubleVerkoopStatus
                }})</i
              >
              <small
                style="display: block; color: red"
                v-if="doubleVerkoopStatus !== 'Nog niet gereserveerd'"
              >
                De dubbele verkoop is al gereserveerd. Om deze verkoop alsnog te
                reserveren, moet de voorraad van de dubbele verkoop worden
                teruggezet.
              </small>
            </p>
            <p>BestelID: {{ selectedVerkoop.inkoopID }}</p>
            <p
              v-if="
                selectedVerkoop.klantInkoopID &&
                verkoopStatus === 'Inkoop Besteld'
              "
            >
              Levering aan klant adres ID: {{ selectedVerkoop.klantInkoopID }}
            </p>

            <p v-if="selectedMagazijn">
              Magazijn: {{ selectedMagazijn.bedrijf }}
            </p>
            <p v-if="!selectedVerkoop.gereserveerd">
              Voor deze bestelling de 3-fase producten gebruiken:

              <label class="switch">
                <input
                  @change="toggle3Fase"
                  v-model="useDrieFase"
                  type="checkbox"
                  checked
                />
                <span class="slider round"></span>
              </label>
            </p>
          </div>
        </div>
        <select
          style="border: 1px solid black; width: 40%"
          v-if="!selectedMagazijn || !selectedVerkoop.gereserveerd"
          v-model="handSelectedMagazijn"
        >
          <option default value="">selecteer een magazijn</option>
          <option
            v-for="magazijn in magazijnen"
            :key="magazijn.id"
            :value="magazijn"
          >
            {{ magazijn.bedrijf }}
          </option>
        </select>
        <small
          v-if="
            selectedVerkoop.aangepastMagazijn &&
            selectedVerkoop.aangepastMagazijn !== selectedVerkoop.magazijn
          "
          style="color: red; display: block"
        >
          Er is een aanpassing gedaan van het magazijn. Van
          {{ selectedVerkoop.magazijn }} naar
          {{ selectedVerkoop.aangepastMagazijn }}
        </small>
        <small v-if="!selectedMagazijn" style="color: red; display: block"
          >Geen magazijn gevonden! Selecteer een magazijn.</small
        >
        <button
          style="margin-left: 1rem"
          v-if="handSelectedMagazijn && !selectedVerkoop.besteld"
          @click="selectMagazijn(handSelectedMagazijn)"
        >
          Opslaan
        </button>
      </div>
      <table>
        <tr>
          <th style="width: 35%">Naam</th>
          <th style="width: 5%">Magazijn</th>
          <th style="width: 5%">Bestel ID</th>
          <th style="width: 5%">Aantal</th>
          <th style="width: 10%">Status</th>
        </tr>
        <tr v-for="i in selectedVerkoop.bestelItems" :key="i.naam">
          <td>{{ (i.naam = formatName(i.naam)) }}</td>
          <td v-if="!selectedVerkoop.gereserveerd">
            <select v-model="i.magazijn" @change="check">
              <option default :value="selectedVerkoop.magazijn">
                {{ selectedVerkoop.magazijn }}
              </option>
              <option value="klant">Direct bij klant reserveren</option>
              <option
                v-for="mag in magazijnen"
                :key="mag.id"
                :value="mag.bedrijf"
              >
                {{ mag.bedrijf }}
              </option>
            </select>
          </td>
          <td v-else>{{ i.magazijn }}</td>
          <td>
            <i
              style="color: blue; text-decoration: underline; cursor: pointer"
              @click="reRoute(`/inkoop/${i.bestelID}`)"
              >{{ i.bestelID }}</i
            >
          </td>
          <td v-if="i.status === 'Gereserveerd'">{{ i.aantal }}</td>
          <td v-if="i.status !== 'Gereserveerd'">
            <input type="number" v-model="i.aantal" />
          </td>
          <td>{{ i.status }}</td>
        </tr>
        <h3 v-if="!selectedVerkoop.gereserveerd">Toevoegen Product</h3>
        <tr v-if="!selectedVerkoop.gereserveerd">
          <td>
            <select v-model="selectedProduct">
              <option default value="">Kies je Product</option>
              <option v-for="p in stock" :key="p.id" :value="p">
                {{ p.id }}
              </option>
            </select>
          </td>
          <td>
            <input
              placeholder="Het aantal"
              type="number"
              v-model="addedAantal"
            />
          </td>

          <td v-if="selectedProduct && addedAantal">
            <button @click="productToevoegen">Toevoegen</button>
          </td>
          <td v-else>Nog niet toegevoegd</td>
        </tr>
      </table>

      <div v-if="selectedVerkoop.backOrderItems">
        <h3
          v-if="selectedVerkoop.backOrderItems.length > 0"
          style="color: red; font-weight: 700; margin-left: 5rem"
        >
          Let op! Er staan in deze installatie mogelijk items in Backorder
        </h3>
        <table v-if="selectedVerkoop.backOrderItems.length > 0">
          <tr>
            <th>Naam</th>
            <th>Verwachte Levertijd</th>
            <th>Bestel ID</th>
            <th>Status</th>
          </tr>
          <tr v-for="i in selectedVerkoop.backOrderItems" :key="i.naam">
            <td>{{ i.naam }}</td>
            <td>{{ i.levertijd }}</td>
            <td
              style="color: blue; text-decoration: underline; cursor: pointer"
              @click="reRoute(`/inkoop/${i.bestelID}`)"
            >
              <i>
                {{ i.bestelID }}
              </i>
            </td>
            <td>{{ i.status }}</td>
          </tr>
        </table>
      </div>

      <table v-if="selectedVerkoop.mutatieItems">
        <h3>Items na mutatie:</h3>

        <p style="margin-left: 5rem" v-if="selectedVerkoop.aangepastMagazijn">
          Magazijn: {{ selectedVerkoop.aangepastMagazijn }}
        </p>
        <select
          style="border: 1px solid black; width: 40%"
          v-if="!selectedVerkoop.aangepastMagazijn"
          v-model="selectedMutatieMagazijn"
          @change="setMutatieMagazijn"
        >
          <option default value="">selecteer een magazijn</option>
          <option
            v-for="magazijn in magazijnen"
            :key="magazijn.id"
            :value="magazijn"
          >
            {{ magazijn.bedrijf }}
          </option>
        </select>

        <tr v-if="selectedVerkoop.mutatieItems">
          <th style="width: 30%">Naam</th>
          <th style="width: 30%">Magazijn</th>
          <th style="width: 5%">Aantal</th>
        </tr>
        <tr v-for="i in selectedVerkoop.mutatieItems" :key="i.naam">
          <td>{{ i.naam }}</td>
          <td>
            <select v-model="i.magazijn" @change="check">
              <option default :value="i.magazijn">
                {{ i.magazijn }}
              </option>
              <option value="klant">Direct bij klant reserveren</option>
              <option
                v-for="mag in magazijnen"
                :key="mag.id"
                :value="mag.bedrijf"
              >
                {{ mag.bedrijf }}
              </option>
            </select>
          </td>
          <td>
            {{ i.aantal }}
            <!-- <input type="number" v-model="i.aantal" /> -->
          </td>
        </tr>
        <h3>Toevoegen Product</h3>
        <tr>
          <td>
            <select v-model="selectedProduct">
              <option default value="">Kies je Product</option>
              <option v-for="p in stock" :key="p.id" :value="p">
                {{ p.id }}
              </option>
            </select>
          </td>
          <td>
            <input
              placeholder="Het aantal"
              type="number"
              v-model="addedAantal"
            />
          </td>

          <td v-if="selectedProduct && addedAantal">
            <button @click="productToevoegen(1)">Toevoegen</button>
          </td>
          <td v-else>Nog niet toegevoegd</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { useLoadVerkoop, useLoadMagazijn, useLoadStock } from "../fb.js";

export default {
  props: ["instal"],
  name: "App",
  setup() {
    let verkoopRecords = useLoadVerkoop();
    let magazijnen = useLoadMagazijn();
    let stock = useLoadStock();
    return { verkoopRecords, magazijnen, stock };
  },
  data() {
    return {
      show: false,
      selectedVerkoop: "",
      selectedMagazijn: "",
      handSelectedMagazijn: "",
      selectedMutatieMagazijn: "",
      intialValue: "",
      aantal: 0,
      addedAantal: 0,
      selectedProduct: "",
      selectedProductKlant: "",
      showBestelItems: false,
      verkoop: "",
      filterFrom: null,
      filterUntill: null,
      bestelID: "",
      mutatieIt: null,
      double: "",
      volledigeLeveringKlant: false,
      counterCheck: 0,
      showErrorMessage: false,
      useDrieFase: false,
    };
  },
  computed: {
    verkoopStatus() {
      if (this.selectedVerkoop.verwijderd) {
        return "Verwijderd";
      } else if (this.selectedVerkoop.afgerond) {
        return "Geïnstalleerd";
      } else if (this.selectedVerkoop.inkoopOrdered) {
        return "Inkoop Besteld";
      } else if (this.selectedVerkoop.gereserveerd) {
        return "Gereserveerd";
      } else {
        return "Nog niet gereserveerd";
      }
    },
    doubleVerkoopStatus() {
      if (this.double.afgerond) {
        return "Geïnstalleerd";
      } else if (this.double.inkoopOrdered) {
        return "Inkoop Besteld";
      } else if (this.double.gereserveerd) {
        return "Gereserveerd";
      } else {
        return "Nog niet gereserveerd";
      }
    },
  },
  methods: {
    formatName(naam) {
      if (!this.selectedVerkoop.gereserveerd) {
        if (
          naam === "Enphase IQ-kabel landscape monofase | 1.7 m tussenafstand"
        ) {
          if (this.useDrieFase) {
            return "Enphase IQ-kabel landscape driefase | 1.7 m tussenafstand";
          } else {
            return naam;
          }
        } else if (naam === "Enphase afsluitstuk IQ-serie MONOfase") {
          if (this.useDrieFase) {
            return "Enphase afsluitstuk IQ-serie driefase";
          } else {
            return naam;
          }
        } else {
          return naam;
        }
      } else {
        return naam;
      }
    },
    toggle3Fase() {
      this.selectedVerkoop.bestelItems.forEach((_items) => {
        if (this.useDrieFase) {
          if (
            _items.naam ===
            "Enphase IQ-kabel landscape monofase | 1.7 m tussenafstand"
          ) {
            _items.naam =
              "Enphase IQ-kabel landscape driefase | 1.7 m tussenafstand";
          } else if (_items.naam === "Enphase afsluitstuk IQ-serie MONOfase") {
            _items.naam = "Enphase afsluitstuk IQ-serie driefase";
          } else if (_items.naam === "Enphase IQ-relay monofase") {
            _items.naam === "Enphase IQ-relay driefase";
          }
        } else {
          if (
            _items.naam ===
            "Enphase IQ-kabel landscape driefase | 1.7 m tussenafstand"
          ) {
            _items.naam =
              "Enphase IQ-kabel landscape monofase | 1.7 m tussenafstand";
          } else if (_items.naam === "Enphase afsluitstuk IQ-serie driefase") {
            _items.naam = "Enphase afsluitstuk IQ-serie MONOfase";
          } else if (_items.naam === "Enphase IQ-relay driefase") {
            _items.naam === "Enphase IQ-relay monofase";
          }
        }
      });
    },

    setMutatieMagazijn() {
      this.selectedVerkoop.mutatieItems.forEach((_items) => {
        _items.magazijn = this.selectedMutatieMagazijn.bedrijf;
      });
    },
    reserverenVoorKlant() {
      this.selectedVerkoop.bestelItems.forEach((val) => {
        if (val.naam === this.selectedProductKlant) {
          val.status = "Gereserveerd naar klant";
        }
      });
      this.$store.dispatch("setVerkoopChanges", this.selectedVerkoop);
      this.showBestelItems = false;
      this.selectedProductKlant = "";
    },
    openDialog() {
      window.open(
        "https://crm.zoho.com/crm/org580648821/tab/CustomModule1/" +
          this.selectedVerkoop.processtapID
      );
    },
    reRoute(path = null) {
      if (!path) {
        this.$router.push(`/verkoop`);
      } else {
        this.$router.push(path);
        // window.location.reload();
      }
    },
    productToevoegen(type = 0) {
      let found = false;
      let status = "Niet gereserveerd";
      let items = this.selectedVerkoop.bestelItems;
      if (type === 1) {
        status = "Gereserveerd";
        items = this.selectedVerkoop.mutatieItems;
      }
      const doc = {
        aantal: this.addedAantal,
        naam: this.selectedProduct.id,
        status: status,
        type: this.selectedProduct.typeHoofdProduct,
      };
      this.selectedVerkoop.gereserveerd = false;
      items.forEach((_item) => {
        if (_item.naam === doc.naam) {
          _item.aantal = _item.aantal + doc.aantal;
          found = true;
        }
      });
      if (!found) {
        items.push(doc);
      }
      this.selectedProduct = "";
      this.addedAantal = 0;
    },
    selectMagazijn(value) {
      this.selectedMagazijn = value;
      if (
        this.selectedMagazijn.useDrieFase &&
        !this.selectedVerkoop.gereserveerd &&
        this.selectedVerkoop.drieFase
      ) {
        this.useDrieFase = true;

        this.selectedVerkoop.bestelItems.forEach((_items) => {
          if (
            _items.naam ===
            "Enphase IQ-kabel landscape monofase | 1.7 m tussenafstand"
          ) {
            _items.naam =
              "Enphase IQ-kabel landscape driefase | 1.7 m tussenafstand";
          }
          if (_items.naam === "Enphase afsluitstuk IQ-serie MONOfase") {
            _items.naam === "Enphase afsluitstuk IQ-serie driefase";
          }

          if (_items.naam === "Enphase IQ-relay monofase") {
            _items.naam === "Enphase IQ-relay driefase";
          }
        });
      }

      this.selectedVerkoop.magazijn = value.bedrijf;
      this.selectedVerkoop.bestelItems.forEach((_items) => {
        if (_items.magazijn !== value.bedrijf) {
          _items.bestelID = "";
        }
        _items.magazijn = value.bedrijf;
      });
      this.handSelectedMagazijn = "";
      this.$store.dispatch("setVerkoopChanges", this.selectedVerkoop);
      // setTimeout(() => {
      //   window.location.reload();
      // }, 200);
    },

    update(i) {
      const changedItem = i;
      let difference = this.aantal - i.aantal;
      i.aantal = this.aantal;
      if (!this.selectedVerkoop.gereserveerd) {
        if (changedItem.zonnepaneel) {
          this.selectedVerkoop.bestelItems.forEach((val) => {
            if (val.type === "variabel" && val.naam !== changedItem.naam) {
              val.aantal = changedItem.aantal;
            }
          });
        }
      }
      this.$store.dispatch("setVerkoopChanges", this.selectedVerkoop);
      if (this.selectedVerkoop.gereserveerd) {
        this.selectedMagazijn.stock.forEach((val) => {
          if (val.productNaam === i.naam) {
            val.adminStock = val.adminStock - difference;
          }
        });
        const d = new Date().valueOf();
        const log2 = {
          afterValue: this.selectedMagazijn,
          collection: "Magazijnen",
          recordId: this.selectedMagazijn.id,
          type: "administratief",
          timeStamp: d,
        };
        const doc2 = {
          id: this.selectedMagazijn.id,
          content: this.selectedMagazijn.stock,
        };

        this.$store.dispatch("saveStock", doc2);
        this.$store.dispatch("createLog", log2);
      }
    },

    async muteren() {
      const checkEmptyMag = this.selectedVerkoop.mutatieItems.find(
        (_items) => _items.magazijn === ""
      );
      if (checkEmptyMag) {
        this.showErrorMessage = true;
      } else {
        await this.verwerken(1);
        // await this.mutatiesDoorvoeren();
        if (this.selectedVerkoop.aangepastMagazijn) {
          this.selectedVerkoop.magazijn =
            this.selectedVerkoop.aangepastMagazijn;
        }
        this.selectedVerkoop.bestelItems = this.selectedVerkoop.mutatieItems;
        await this.verwerken(0);

        this.selectedVerkoop.mutatie = false;
        delete this.selectedVerkoop.mutatieItems;
        if (this.selectedVerkoop.aangepastMagazijn) {
          delete this.selectedVerkoop.aangepastMagazijn;
        }
        this.selectedVerkoop.bestelID = "";
        this.$store.dispatch("setVerkoopChanges", this.selectedVerkoop);
      }
    },

    setVerwijderen() {
      this.selectedVerkoop.verwijderd = true;
      this.$store.dispatch("setVerkoopChanges", this.selectedVerkoop);
    },

    async afrondItem(item, type = 0) {
      let mag = this.magazijnen.find((val) => val.bedrijf === item.magazijn);

      const foundObj = mag.stock.find(
        (_stock) => _stock.productNaam === item.naam
      );

      if (type === 0) {
        foundObj.fysiekStock = foundObj.fysiekStock + item.aantal * -1;
      } else {
        foundObj.fysiekStock = foundObj.fysiekStock + item.aantal;
      }

      if (type === 1) {
        item.status = "Besteld";
      } else if (type === 0 && item.bestelID) {
        item.status = "Afgerond";
      }
      const d = new Date().valueOf();
      const log2 = {
        afterValue: mag,
        collection: "Magazijnen",
        recordId: mag.id,
        type: "fysiek",
        timeStamp: d.toString(),
      };
      this.$store.dispatch("createLog", log2);
      await this.$store.dispatch("saveStock", {
        id: mag.id,
        content: mag.stock,
      });

      const log = {
        afterValue: this.selectedVerkoop,
        collection: "Verkoop",
        recordId: this.selectedVerkoop.id,
        type: "edit",
        timeStamp: d.toString(),
      };

      this.$store.dispatch("createLog", log);
      this.$store.dispatch("setVerkoopChanges", this.selectedVerkoop);
    },
    async afronden(type = 0) {
      if (type === 0) {
        this.selectedVerkoop.afgerond = true;
      } else {
        this.selectedVerkoop.afgerond = false;
      }
      this.selectedVerkoop.bestelItems.forEach((_items) => {
        if (
          type === 0 &&
          _items.status !== "Niet gereserveerd" &&
          _items.magazijn !== "klant"
        ) {
          this.afrondItem(_items, type);
        } else if (
          type === 1 &&
          _items.status === "Afgerond" &&
          _items.magazijn !== "klant"
        ) {
          this.afrondItem(_items, type);
        }
      });
    },

    async verwerkItem(item, type) {
      let mag = this.magazijnen.find((val) => val.bedrijf === item.magazijn);
      if (mag.stock.length > 0) {
        const foundObj = mag.stock.find(
          (_stock) => _stock.productNaam === item.naam
        );
        if (foundObj) {
          if (type === 0) {
            foundObj.adminStock = foundObj.adminStock + item.aantal * -1;
          } else {
            foundObj.adminStock = foundObj.adminStock + item.aantal;
          }
        } else {
          const doc = {
            adminStock: item.aantal * -1,
            fysiekStock: 0,
            productNaam: item.naam,
            saved: true,
            bijgewerkt: false,
          };
          mag.stock.push(doc);
        }
      } else {
        const doc = {
          adminStock: item.aantal * -1,
          fysiekStock: 0,
          productNaam: item.naam,
          saved: true,
          bijgewerkt: false,
        };
        mag.stock.push(doc);
      }

      if (
        // item.aantal < 0 ||
        type === 1
      ) {
        item.status = "Niet gereserveerd";
      } else if (type === 0 && item.bestelID) {
        item.status = "Besteld";
      } else if (type === 0 && !item.bestelID) {
        item.status = "Gereserveerd";
      }
      const d = new Date().valueOf();
      const log2 = {
        afterValue: mag,
        collection: "Magazijnen",
        recordId: mag.id,
        type: "administratief",
        timeStamp: d.toString(),
      };
      this.$store.dispatch("createLog", log2);
      await this.$store.dispatch("saveStock", {
        id: mag.id,
        content: mag.stock,
      });

      const log = {
        afterValue: this.selectedVerkoop,
        collection: "Verkoop",
        recordId: this.selectedVerkoop.id,
        type: "edit",
        timeStamp: d.toString(),
      };

      this.$store.dispatch("createLog", log);
      this.$store.dispatch("setVerkoopChanges", this.selectedVerkoop);
    },

    async verwerken(type = 0) {
      if (type === 0) {
        let allesBesteld = true;
        this.selectedVerkoop.gereserveerd = true;
        const allesBesteldCheck = this.selectedVerkoop.bestelItems.find(
          (_items) => !_items.bestelID
        );
        if (allesBesteldCheck) {
          allesBesteld = false;
        }
        if (allesBesteld) {
          this.selectedVerkoop.inkoopOrdered = true;
        }
      } else {
        this.selectedVerkoop.gereserveerd = false;
        if (this.selectedVerkoop.klantBesteld) {
          this.selectedVerkoop.klantBesteld = false;
        }
        if (this.selectedVerkoop.inkoopOrdered) {
          this.selectedVerkoop.inkoopOrdered = false;
        }
      }

      this.selectedVerkoop.klantLevering = false;
      this.selectedVerkoop.bestelItems =
        this.selectedVerkoop.bestelItems.filter(
          (_items) => _items.aantal !== 0
        );

      if (this.selectedVerkoop.backOrderItems) {
        if (this.selectedVerkoop.backOrderItems.length > 0) {
          this.selectedVerkoop.backOrderItems.forEach((_item) => {
            const missing = this.selectedVerkoop.bestelItems.findIndex(
              (val) => val.naam === _item.naam
            );
            if (missing === -1) {
              this.selectedVerkoop.backOrderItems =
                this.selectedVerkoop.backOrderItems.filter(
                  (_items) => _items.naam !== _item.naam
                );
            }
          });
        }
      }

      let zohoObj = {
        zonnepaneel: "",
        omvormer: "",
        psID: this.selectedVerkoop.processtapID,
      };
      this.selectedVerkoop.bestelItems.forEach((val) => {
        const t = this.stock.find((_stock) => _stock.id === val.naam);
        if (t.zonnepaneel) {
          zohoObj.zonnepaneel = val.naam;
        } else if (t.omvormer) {
          zohoObj.omvormer = val.naam;
        }

        if (val.aantal !== 0 && val.magazijn) {
          if (val.magazijn === "klant") {
            if (type === 0 && val.status !== "Besteld bij klant") {
              val.status = "Gereserveerd bij klant";
              this.selectedVerkoop.klantLevering = true;
            } else if (type === 1 && val.status === "Gereserveerd bij klant") {
              val.status = "Niet gereserveerd";
            }
          } else {
            this.verwerkItem(val, type);
          }
        }
      });
      if (type === 0) {
        this.$store.dispatch("setRequest", zohoObj);
      }
    },

    bestelText(value = false, value2 = false, value3 = false, value4 = false) {
      let returnText = "Nog niet gereserveerd";
      if (
        value === true &&
        value2 === false &&
        value3 === false &&
        value4 === false
      ) {
        returnText = "Gereserveerd";
      } else if (value2 === true) {
        returnText = "Geannuleerd";
      } else if (value3 === true) {
        returnText = "Inkoop Besteld";
      } else if (value4 === true) {
        returnText = "Afgerond";
      }
      return returnText;
    },
    getDate(date) {
      date = +date;
      return this.moment(date).format("DD-MM-YYYY");
    },
    getDateTime(date) {
      date = +date;
      return this.moment(date).format("DD-MM-YYYY HH:mm:ss");
    },
  },
  created() {
    // if (this.instal)
    // {
    //   console.log("install ", this.instal);
    // }
    this.moment = moment;
    setTimeout(() => {
      this.selectedVerkoop = this.verkoopRecords.find(
        (_verkoop) => _verkoop.id === this.instal
      );
      const rec = this.verkoopRecords.find(
        (val) =>
          val.id !== this.selectedVerkoop.id &&
          val.klantNummer === this.selectedVerkoop.klantNummer &&
          !val.verwijderd
      );
      if (rec) {
        this.double = rec;
      }
      this.selectedMagazijn = this.magazijnen.find(
        (_magazijn) => _magazijn.bedrijf === this.selectedVerkoop.magazijn
      );
      if (
        this.selectedMagazijn.useDrieFase &&
        !this.selectedVerkoop.gereserveerd &&
        this.selectedVerkoop.drieFase === true
      ) {
        this.useDrieFase = true;
      }
      // this.filter = this.$route;
      // return this.filter;
    }, 500);
  },
  watch: {
    $route() {
      window.location.reload();
    },
  },
};
</script>

<style scoped>
.rootDiv {
  padding: 1rem;
}

.contentWrapper {
  display: grid;
  grid-template-columns: 50% 50%;
}

.link {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

button + button {
  margin-right: 1rem;
}

.filterWrapper {
  display: flex;
}

.content {
  padding: 2rem;
}

.alert {
  background-color: orange;
  /* opacity: 0.6; */
}

.iconBack {
  transform: rotate(45deg);
  padding: 0.5rem;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid black;
  float: right;
  cursor: pointer;
}

.titleText {
  margin-left: 2rem;
}
.title {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  transition: all 0.3s ease-in-out;
}
.title:hover {
  background-color: #44bace;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.title {
  border: 1px solid black;
  padding: 5px;
}

.description {
  border: 1px solid grey;
  border-radius: 12px;
  padding: 5px;
}

.active {
  background-color: #44bace;
  color: #fff;
}

.genContent {
  margin: auto;
  width: 90%;

  text-align: left;
}

table {
  margin: auto;
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 90%;
  margin-bottom: 2rem;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

.btn {
  float: right;
}

.btnUpdate {
  margin-left: 1rem;
  vertical-align: middle;
}

.icon {
  border: 1px solid transparent;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  padding: 0.5rem;
  float: right;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  z-index: 10;
}

.icon:hover {
  border: 1px solid #44bace;
  color: white;
  background-color: #44bace;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
}

.dialogView {
  margin: auto;
  /* left: 60%; */
  /* right: auto; */
  z-index: 15;
}

dialog {
  max-width: 60rem;
  max-height: 90vh;
  /* z-index: 100; */
  border: none;
  overflow-y: auto;
  background-color: transparent;
}

button {
  border: none;
  height: 2.3rem;
  padding: 0.5rem;
  border-radius: 12px;
  background-color: #44bace;
  cursor: pointer;
  color: #fff;
}

.dialog-enter-from,
.dialog-leave-to {
  opacity: 0;
  transform: scale(0.8);
}

.dialog-enter-active {
  transition: all 0.3s ease-out;
}

.dialog-leave-active {
  transition: all 0.3 ease-in;
}

.dialog-enter-to,
.dialog-leave-from {
  opacity: 1;
  transform: scale(1);
}

select,
input {
  border: 1px solid black;
  border-radius: 12px;
  padding: 0.5rem;
  margin-bottom: 1rem;
  margin-right: 2rem;
}

.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
