import db from "../../fb.js";
import firebase from "firebase/app";

const inkoopCollectie = db.collection("Inkoop");
const magazijnenCollection = db.collection("Magazijnen");
const stockCollection = db.collection("Producten");
// const stockCollection = db.collection("Stock");
const verkoopCollection = db.collection("Verkoop");

const user = localStorage.getItem("user");

export default {
  async setItem(context, payload) {
    context.commit("setItem", payload);
  },

  async createLog(_, payload) {
    let initialValue = "";
    if (payload.type !== "create") {
      await db
        .collection(`${payload.collection}`)
        .doc(`${payload.recordId}`)
        .get()
        .then((doc) => {
          initialValue = doc.data();
        });
    }
    const d = new Date().valueOf();
    const doc = {
      timeStamp: payload.timeStamp,
      beforeValue: initialValue,
      afterValue: payload.afterValue,
      modifiedBy: user,
      collection: payload.collection,
      recordId: payload.recordId,
      type: payload.type,
    };

    db.collection("Mutaties").doc(`${d}`).set(doc);
  },

  async setVerkoopChanges(_, payload) {
    await verkoopCollection.doc(payload.id).set(payload);
  },

  async updateProductPrijzen(_, payload) {
    await stockCollection.doc(payload.id).update({ prijzen: payload.content });
  },

  async updateStockField(_, payload) {
    await stockCollection.doc(payload.id).update(payload.content);
  },

  async updateInkoop(_, payload) {
    let id = payload.id.toString();
    await inkoopCollectie.doc(`${id}`).set(payload);
  },

  async addMagazijn(_, payload) {
    await magazijnenCollection.add(payload);
  },

  async updateMagazijn(_, payload) {
    await magazijnenCollection.doc(payload.id).set(payload);
  },

  async logOut() {
    await firebase.auth().signOut();
  },

  async createInkoop(_, payload) {
    const da = payload.bestelDatum.toString();
    await inkoopCollectie.doc(da).set(payload);
  },

  async createProduct(_, payload) {
    await stockCollection.doc(payload.id).set(payload.content);
  },

  getVerkoop(_, payload) {
    let obj = {};
    verkoopCollection
      .doc(payload)
      .get()
      .then((doc) => {
        obj = doc.data();
      });
    return { obj };
  },

  async setRequest(_, payload) {
    let d = new Date().valueOf();
    db.collection("Requests").doc(`${d}`).set(payload);
  },

  async setVerkoopToBesteld(_, payload) {
    const array = [];
    await verkoopCollection
      .where("gereserveerd", "==", true)

      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          let volledigBesteld = true;
          const rec = doc.data();

          if (!rec.afgerond && !rec.inkoopOrdered) {
            rec.bestelItems.forEach((_items) => {
              if (_items.magazijn === payload.magazijn) {
                if (_items.status === "Gereserveerd") {
                  _items.status = "Besteld";
                  _items.bestelID = payload.id;
                  if (!array.includes(doc.id)) {
                    array.push(doc.id);
                  }
                } else if (_items.status === "Gereserveerd bij klant") {
                  (_items.status = "Besteld bij klant"),
                    (_items.bestelID = payload.id);
                  if (!array.includes(doc.id)) {
                    array.push(doc.id);
                  }
                }
              }
              // if (
              //   _items.status !== "Besteld" ||
              //   _items.status !== "Besteld bij klant"
              // ) {
              //   volledigBesteld = false;
              // }
            });

            const r = rec.bestelItems.find((_items) => _items.status !== "Besteld" && _items.status !== "Besteld bij klant");
            if (r) {
              volledigBesteld = false;
            }
            console.log(volledigBesteld);
              verkoopCollection.doc(doc.id).update({
                inkoopOrdered: volledigBesteld,
                inkoopID: payload.id,
                bestelItems: rec.bestelItems,
              });
          }
        });
      });
    setTimeout(() => {
      if (array.length > 0) {
        inkoopCollectie.doc(payload.id).update({ linkedVerkoop: array });
      }
    }, 2000);
  },

  async saveStock(_, payload) {
    payload.showAlert = false;
    payload.content.forEach((val) => {
      if (val.adminStock < 0 || val.fysiekStock < 0) {
        payload.showAlert = true;
      }
    });
    magazijnenCollection.doc(payload.id).update({
      stock: payload.content,
      showAlert: payload.showAlert,
    });
  },
};
