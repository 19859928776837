<template>
  <div class="bar">
    <button
      :class="{
        active: path === '/dashboard',
      }"
      @click="routeTo('/dashboard')"
    >
      Dashboard
    </button>
    <button
      :class="{
        active: path === '/verkoop',
      }"
      @click="routeTo('/verkoop')"
    >
      Installaties
    </button>
    <button
      :class="{
        active: path === '/inkoop',
      }"
      @click="routeTo('/inkoop')"
    >
      Inkoop
    </button>
    <button
      :class="{
        active: path === '/magazijn',
      }"
      @click="routeTo('/magazijn')"
    >
      Magazijn
    </button>
    <button
      :class="{
        active: path === '/producten',
      }"
      @click="routeTo('/producten')"
    >
      Producten
    </button>
    <button
      :class="{
        active: path === '/reports',
      }"
      @click="routeTo('/reports')"
    >
      Rapporten
    </button>
    <font-awesome-icon class="icon" icon="power-off" @click="logOff" />
  </div>
</template>

<script>
export default {
  methods: {
    routeTo(val) {
      this.$router.push(val);
    },

    logOff() {
      this.$store.dispatch("logOut");
      window.location.reload();
    },
  },
  computed: {
    path() {
      return this.$route.path;
    },
  },
};
</script>

<style scoped>
.icon {
  cursor: pointer;
}

.active {
  text-decoration: underline;
  font-weight: 700;
}

.bar {
  z-index: 100;
  background-color: #44bace;
  height: 2.5rem;
  position: relative;
  width: 100%;
  display: flex;
  color: #fff;
  justify-content: space-around;
  align-items: center;
  position: fixed;
}

button {
  cursor: pointer;
  padding: 0 0.5rem;
  background-color: transparent;
  color: #fff;
  border: none;
  transition: 0.3s all ease-in-out;
}
button:hover,
button:focus {
  background-color: #fff;
  color: #44bace;
  padding: 0.5rem;
  border-radius: 12px;
}
</style>
