<template>
  <div class="rootDiv">
        <div v-if="showLoading" class="loadingContainer">
      <div class="honeycomb">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div class="dialog" v-if="!!show">
      <teleport to="body">
        <div class="backdrop">
          <transition>
            <div class="dialogView">
              <dialog open>
                <add-inkoop @close="closing"></add-inkoop>
              </dialog>
            </div>
          </transition>
        </div>
      </teleport>
    </div>

    <div class="content" v-if="!showLoading">
      <font-awesome-icon
        style="margin-right: 3rem"
        class="icon"
        icon="plus"
        @click="show = true"
      />
      <div v-if="inkoop.length === 0" style="display:flex; justify-content:center; align-items: center; height: 100vh; color:grey"><h3>Geen Inkooprecords gevonden...</h3></div>
      <h2 v-if="inkoop.length > 0">Inkoop bestellingen</h2>
      <table v-if="inkoop.length > 0">
        <tr>
          <th>Besteldatum</th>
          <th>Leveringsdatum</th>
          <th>Leverancier</th>
          <th>Status</th>
          <th>Type</th>
          <th>Backorder</th>
        </tr>
        <tr
          v-for="i in inkoop"
          :key="i.id"
          class="title"
          @click="reRoute(`/inkoop/${i.id}`)"
          :class="{ active: i.expanded, alert: i.itemsInBackorder }"
        >
          <td>{{getDate(i.bestelDatum)}} </td>
          <td>{{ i.leveringsDatum || "Nog niet bekend"}} </td>
          <td>{{i.leverancier}} </td>
          <td>{{verwerktText(i.verwerkt)}} </td>
          <td>{{klantText(i.afleverLocatie)}} </td>
          <td v-if="i.itemsInBackorder">JA</td>
          <td v-else>NEE</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import AddInkoop from "./AddInkoop.vue";

import { useLoadInkoop } from "../fb.js";

export default {
  name: "App",
  components: { AddInkoop },
  setup() {
    let inkoop = useLoadInkoop();

    return { inkoop };
  },
  data() {
    return {
      show: false,
      selectedValue: "",
      showLoading: false,
    };
  },

  methods: {
    klantText(value) {
      if (value === "klant") {
        return "Levering aan klant";
      } else {
        return "Levering aan magazijn";
      }
    },
    verwerktText(value) {
      if (value === true) {
        return "Verwerkt";
      } else {
        return "Nog niet verwerkt";
      }
    },
    reRoute(path) {
      this.$router.push(path);
    },
    getDate(date) {
      console.log(date);
      date = +date;
      return this.moment(date).format("DD-MM-YYYY, HH:mm:ss");
    },
    closing() {
      this.show = false;
    },
  },
  created() {
    this.moment = moment;
    this.showLoading = true;
    setTimeout(() => {this.showLoading = false}, 2500)
  },
};
</script>
<style scoped>
.content {
  padding: 2rem;
}

.alert {
  background-color: orange;
  /* opacity: 0.6; */
}

.iconBack {
  transform: rotate(45deg);
  padding: 0.5rem;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid black;
  float: right;
  cursor: pointer;
}

.titleText {
  margin-left: 2rem;
}
.title {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.title:hover {
  background-color: #44bace;
  cursor: pointer;
}
.title,
.description {
  border: 1px solid black;
  padding: 5px;
}

.active {
  background-color: #44bace;
  color: #fff;
}

.icon {
  border: 1px solid transparent;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  padding: 0.5rem;
  float: right;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  z-index: 10;
}

.icon:hover {
  border: 1px solid #44bace;
  color: white;
  background-color: #44bace;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
}

.dialogView {
  margin: auto;
  /* left: 60%; */
  /* right: auto; */
  z-index: 15;
}

dialog {
  max-width: 60rem;
  max-height: 90vh;
  /* z-index: 100; */
  border: none;
  overflow-y: auto;
  background-color: transparent;
}

button {
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 12px;
  background-color: #44bace;
  cursor: pointer;
  color: #fff;
}

.dialog-enter-from,
.dialog-leave-to {
  opacity: 0;
  transform: scale(0.8);
}

.dialog-enter-active {
  transition: all 0.3s ease-out;
}

.dialog-leave-active {
  transition: all 0.3 ease-in;
}

.dialog-enter-to,
.dialog-leave-from {
  opacity: 1;
  transform: scale(1);
}

table {
  margin: auto;
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 2rem;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}


/* honeycomb css */

.loadingContainer {
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;
  overflow: hidden;
  height: 100vh;
  width: 100%;
}


/**
  * Honeycomb
  *
  * @author jh3y - jheytompkins.com
*/
@-webkit-keyframes honeycomb {
  0%,
  20%,
  80%,
  100% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  30%,
  70% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes honeycomb {
  0%,
  20%,
  80%,
  100% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  30%,
  70% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.honeycomb {
  height: 24px;
  position: relative;
  width: 24px;
}
.honeycomb div {
  -webkit-animation: honeycomb 2.1s infinite backwards;
  animation: honeycomb 2.1s infinite backwards;
  background: lightgrey;
  height: 12px;
  margin-top: 6px;
  position: absolute;
  width: 24px;
}
.honeycomb div:after,
.honeycomb div:before {
  content: "";
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  position: absolute;
  left: 0;
  right: 0;
}
.honeycomb div:after {
  top: -6px;
  border-bottom: 6px solid lightgrey;
}
.honeycomb div:before {
  bottom: -6px;
  border-top: 6px solid lightgrey;
}
.honeycomb div:nth-child(1) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  left: -28px;
  top: 0;
}
.honeycomb div:nth-child(2) {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
  left: -14px;
  top: 22px;
}
.honeycomb div:nth-child(3) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
  left: 14px;
  top: 22px;
}
.honeycomb div:nth-child(4) {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
  left: 28px;
  top: 0;
}
.honeycomb div:nth-child(5) {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
  left: 14px;
  top: -22px;
}
.honeycomb div:nth-child(6) {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
  left: -14px;
  top: -22px;
}
.honeycomb div:nth-child(7) {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
  left: 0;
  top: 0;
}
</style>
