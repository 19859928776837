<template>
  <div class="rootDiv">
    <font-awesome-icon class="icon" icon="plus" @click="close" />
    <h3>Product Toevoegen</h3>
    <div class="wrapper">
      <p class="left">Naam Product:</p>
      <input type="text" v-model="productName" />
    </div>
    <div class="wrapper">
      <p class="left">Artikelnummer:</p>
      <input type="text" v-model="articleNumber" />
    </div>
    <div class="wrapper">
      <p class="left">Type Product:</p>
      <select v-model="typeHoofdProduct">
        <option default value="">Kies type</option>
        <option value="absoluut">Absoluut (aantal is altijd hetzelfde)</option>
        <option value="variabel">
          Variabel (aantal groeit mee met bepaald product)
        </option>
      </select>
    </div>
    <h4>Prijzen:</h4>
    <div class="wrapper">
      <p class="left">Leverancier:</p>

      <select name="leverancier" v-model="leverancier">
        <option default value="">Kies de leverancier</option>
        <option v-for="lev in leveranciers" :key="lev.id" :value="lev.naam">
          {{ lev.naam }}
        </option>
      </select>

      <p class="left">Prijs Ex BTW:</p>
      <input type="number" v-model="prijsExBtw" />
      <p class="left">Kortinspercentage:</p>
      <input type="number" v-model="korting" />
    </div>
    <div class="wrapper">
      <p class="left">Korting van:</p>
      <input type="date" v-model="van" />
      <p class="left">Korting tot:</p>
      <input type="date" v-model="tot" />
      <font-awesome-icon class="plus" icon="plus" @click="addPrice" />
    </div>
    <div class="priceDiv" v-for="(p, index) in prijzen" :key="p.naam">
      <small style="display: block"
        >{{ p.naam }} : €{{ p.prijsExBtw }} | {{ p.korting }}% korting
        <font-awesome-icon
          class="minus"
          icon="minus"
          @click="removePrice(index)"
        />
      </small>
    </div>
    <button class="btn" @click="createProduct">Opslaan</button>
  </div>
</template>

<script>
import { useLoadStock, useLoadLeveranciers } from "../fb.js";

export default {
  setup() {
    let stock = useLoadStock();
    let leveranciers = useLoadLeveranciers();
    return { stock, leveranciers };
  },
  data() {
    return {
      leverancier: "",
      articleNumber: "",
      prijzen: [],
      subProduct: "",
      subProducten: [],
      productName: "",
      typeHoofdProduct: "",
      korting: 0,
      prijsExBtw: 0,
      typeProduct: "",
      van: "",
      tot: "",
      aantal: 0,
    };
  },
  computed: {
    formatFactuurBedrag() {
      return this.factuurBedrag.toFixed(2);
    },
    buttonText() {
      if (this.actief) {
        return "Zet dit magazijn op Inactief";
      } else {
        return "Zet dit magazijn op Actief";
      }
    },
    activityClass() {
      if (this.actief) {
        return "non-active";
      } else {
        return "active";
      }
    },
  },
  methods: {
    addPrice() {
      if (this.prijsExBtw && this.leverancier) {
        const doc = {
          naam: this.leverancier,
          prijsExBtw: this.prijsExBtw,
          korting: this.korting,
          kortingVan: new Date(this.van).valueOf(),
          kortingTot: new Date(this.tot).valueOf(),
        };
        this.prijzen.push(doc);
        this.prijsExBtw = 0;
        this.leverancier = "";
        this.korting = 0;
        this.van = "";
        this.tot = "";
      }
    },
    removePrice(i) {
      this.prijzen.splice(i, 1);
    },
    AddSubProduct() {
      if (this.subProduct && this.typeProduct && this.aantal) {
        const doc = {
          naam: this.subProduct,
          type: this.typeProduct,
          aantal: this.aantal,
        };
        this.subProducten.push(doc);
        this.subProduct = "";
        this.typeProduct = "";
        this.aantal = 0;
      }
    },
    removeProduct(i) {
      this.subProducten.splice(i, 1);
    },
    createProduct() {
      const doc = {
        typeHoofdProduct: this.typeHoofdProduct,
        prijzen: this.prijzen,
        artikelNummer: this.articleNumber,
        subProducten: this.subProducten,
        active: true,
        status: "Active",
      };
      this.$store.dispatch("createProduct", {
        id: this.productName,
        content: doc,
      });
      this.close();
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.rootDiv {
  width: 100%;
  overflow-y: scroll;
}

.left {
  width: 8%;
}

.line {
  padding: 2rem 0;
  width: 100%;
  border-bottom: 1px solid grey;
}
.non-active {
  background-color: red;
  border: none;
  color: white;
  border-radius: 12px;
  padding: 1rem 1.5rem;
  margin-bottom: 0.5rem;
  transition: 0.3s all ease-in-out;
  cursor: pointer;
}

.non-active:hover {
  background-color: rgb(251, 26, 26);
  box-shadow: rgba(0, 0, 0, 0.34) 0px 3px 8px;
}

.active {
  background-color: #60ab2d;
  border: none;
  color: white;
  border-radius: 12px;
  padding: 1rem 1.5rem;
  margin-bottom: 0.5rem;
  transition: 0.3s all ease-in-out;
  cursor: pointer;
}

.active:hover {
  background-color: #83c51c;
  box-shadow: rgba(0, 0, 0, 0.34) 0px 3px 8px;
}

.minus {
  margin-left: 1rem;
  background-color: grey;
  border-radius: 50%;
  color: white;
  padding: 0.1rem;
  transition: 0.3s all ease-in-out;
  cursor: pointer;
}
.minus:hover {
  margin-left: 1rem;
  background-color: red;
  border-radius: 50%;
  color: white;
  padding: 0.1rem;
}

.plus {
  margin: auto;
  float: right;
  border: 1px solid grey;
  border-radius: 50%;
  padding: 0.5rem;
  transition: 0.3s all ease-in-out;
  cursor: pointer;
}

.plus:hover {
  border: 1px solid #83c51c;
  background-color: #83c51c;
  color: white;
  border-radius: 50%;
  padding: 0.5rem;
}

.icon {
  transform: rotate(45deg);
  float: right;
  border: 1px solid grey;
  border-radius: 50%;
  padding: 0.5rem;
  transition: 0.3s all ease-in-out;
  cursor: pointer;
}

.icon:hover {
  border: 1px solid #44bace;
  background-color: #44bace;
  color: white;
  border-radius: 50%;
  padding: 0.5rem;
}

.btn {
  cursor: pointer;
  margin-top: 2rem;
  border: none;
  padding: 0.5rem 1rem;
  color: #fff;
  background-color: #44bace;
  border-radius: 12px;
  border: 2px solid transparent;
  transition: 0.3s all ease-in-out;
}

.btn:hover {
  border: 2px solid #358391;
}

.wrapper {
  margin: 1rem 0;
  display: flex;
  gap: 2rem;
}

.rootDiv {
  background-color: #fff;
  padding: 2rem;
  border-radius: 12px;
}

input,
select {
  width: 30%;
  border: 1px solid #cccccc;
  padding: 1rem 1rem 1rem;
  border-radius: 0.4rem;
  background-color: #fff;
  transition: 1.5s all ease-in-out;
}

.priceLabel {
  margin: auto;
}

input:focus {
  width: 30%;
  border: 1px solid #44bace;
  padding: 1rem 1rem 1rem;
  border-radius: 0.4rem;
  background-color: lightgrey;
}

table {
  margin: auto;
  margin-top: 1rem;
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 2rem;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}
</style>
