<template>
  <div class="login">
    <div class="mainContent" v-if="!showReset">
      <input
        @keypress.enter="login"
        class="mainInput"
        type="text"
        v-model="user"
        placeholder="Gebruikersnaam"
      /><br />
      <input
        @keypress.enter="login"
        class="mainInput"
        type="password"
        v-model="pw"
        placeholder="Wachtwoord"
      />
      <p class="errorMessage" v-if="showError">
        {{ errorMsg }}
      </p>
      <div><i @click="showReset = true"> Forgot Password?</i></div>
      <button @click="login" class="loginButton">Login</button>
    </div>
    <div class="mainContent" v-if="showReset && !emailSent">
      <input
        @keypress.enter="login"
        class="mainInput"
        type="text"
        v-model="user"
        placeholder="Gebruikersnaam"
      /><br />
      <button @click="sendMail(user)" class="loginButton">Send Reset Mail</button>
    </div>
    <div class="mainContent" v-if="showReset && emailSent">
      <p>Er is een mail verzonden naar het adres dat je hebt ingevoerd. Volg de instructies om opnieuw in te loggen.</p>
      <button @click="showReset = false" class="loginButton">Terug naar Login</button>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";
export default {
  data() {
    return {
      errorMsg: "",
      showError: false,
      showReset: false,
      emailSent: false,
      user: "",
      pw: "",
    };
  },
  methods: {
    check() {
    },
    async login() {
      await firebase
        .auth()
        .signInWithEmailAndPassword(this.user, this.pw)
        .catch((error) => {
          this.loggedIn = false;
          this.showError = true;
          this.errorMsg = error.message;
        })
        .then((cred) => {
          if (cred) {
            this.loggedIn = true;
            this.showError = false;
          }
        });
      this.email = "";
      this.password = "";
    },
    sendMail(value) {
      firebase.auth().sendPasswordResetEmail(value).then(function() {
        console.log(`email sent to ${value}`);
      }).catch(function(error) {
        console.log(error);
      })

      this.emailSent = true;
    },
  },
};
</script>

<style scoped>
.login {
  display: flex;
  height: 100vh;
  width: 100%;
  background-image: url("https://firebasestorage.googleapis.com/v0/b/online-offerte-tool.appspot.com/o/Pages%2FLogin%2FHalloStroom_Logo_CMYK.svg?alt=media&token=b9d9decc-713c-4658-9fbe-571c2c77324a");
  background-repeat: no-repeat;
  background-position: center;
  background-color: #44bace;
}

.mainContent {
  display: block;
  padding: 3rem;
  background-color: #fff;
  border: 1px solid black;
  border-radius: 12px;
  margin: auto;
}

.mainInput {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 95%;
  padding: 0.8rem 0.5rem;
  border-radius: 12px;
  border: 1px grey solid;
}

.loginButton {
  border: none;
  background-color: #44bace;
  padding: 0.6rem 1rem;
  margin-top: 1rem;
  border-radius: 12px;
  color: white;
}

.errorMessage {
  font-size: smaller;
  text-align: left;
  font-style: italic;
  color: red;
  margin-left: 2%;
}

i {
  color: blue;
  text-decoration: underline;
  font-size: 0.7rem;
  cursor: pointer;
}
</style>
