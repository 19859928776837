<template>
  <div class="rootDiv">
    <div class="content">
      <h2>Rapporten</h2>
      <h3>Totaal Overzicht:</h3>
      <table id="tableID">
        <tr>
          <th></th>
          <th style="" v-for="mag in magazijnen" :key="mag.id">
            <p class="headers">
              {{ mag.bedrijf }}
            </p>
          </th>
          <th><p>Totaal</p></th>
        </tr>
          <tr v-for="(st, index) in stocks" :key="st.id" :id="st.id">
          <td class="products">
            {{ st.id }}
          </td>
          <td v-for="mag in magazijnen" :key="mag.id">
            <div style="text-align: center" >{{ mag.val = getValue(mag, st) || ""}}</div>
          </td>
          <td class="total" :class="{positive: st.total > 0}">{{ st.total = check(index) || ""}}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { useLoadMagazijn, useLoadStock } from "../fb.js";

export default {
  setup() {
    let stocks = useLoadStock();
    let magazijnen = useLoadMagazijn();

    return { stocks, magazijnen };
  },
  methods: {
    getValue(magazijn, product) {
      if (magazijn.stock) {
        const val = magazijn.stock.find(
          (_stock) => _stock.productNaam === product.id
        );
        if (val && val.fysiekStock > 0) {
          return val.fysiekStock;
        }
      }
    },
    check(index) {
        const product = this.stocks[index];
        let total = 0;
        this.magazijnen.forEach((_magazijn) => {
            if (_magazijn.stock) {
                const prod = _magazijn.stock.find((val) => val.productNaam === product.id);
                if (prod) {
                    total = total + prod.fysiekStock;
                }
            }
        });
            return total;

    },
  },
};
</script>

<style scoped>
* + * {
  padding: 0;
  margin: 0;
}

.rootDiv {
  width: 100%;
}

.positive {
  background-color: green;
  color: white;
}

.negative {
  color: red;
}

.content {
  overflow-x: scroll;
  padding: 2rem;
}

table {
  margin: auto;
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 90%;
  margin-bottom: 2rem;
}

td,
th {
  border: 1px solid #dddddd;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

.headers {
  width: 19rem;
  margin-left: -8rem;
  font-size: 0.8rem;
}

.products {
  font-size: 0.8rem;
  margin: 0.5rem 1rem;
  width: 25rem;
}

.total {
  font-weight: 700;
  text-align: center;
}

th {
  transform: rotate(90deg);
  height: 20rem;
  text-align: justify;
  max-width: 1rem;
}
</style>
