<template>
  <div class="rootDiv">
    <div class="genContent">
      <p>Tijdstempel: {{ getDate(initialValue.timeStamp) }}</p>
      <p>Aanpassing gedaan door: {{ initialValue.modifiedBy }}</p>
      <p>Collectie: {{ initialValue.collection }}</p>
      <p>Type aanpassing: {{ initialValue.type }}</p>
    </div>
    <button @click="getData">GET DATA</button>
    <table>
      <tr>
        <th style="width: 33%">Veldnaam</th>
        <th style="width: 33%">Oude Waarde</th>
        <th style="width: 33%">Nieuwe Waarde</th>
      </tr>
      <!-- <tr v-for="(item) of initialValue.bestelItems" :key="item.naam">
        <td>{{ item.artikelNummer }}</td>
        <td>{{ item.naam }}</td>
        <td>{{ item.aantal }}</td>
      </tr> -->
    </table>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: ["initialValue"],
  computed: { 

    },
  methods: {
    getData() {
        const array = [];
        const before = this.initialValue.beforeValue;
        const after = this.initialValue.afterValue;

        for (let x in before) {
          if (before[x] !== after[x]) {
          
          array.push({fieldName: x, beforeVal: before[x], afterVal: after[x] });
        }
        }
    },
    getDate(date) {
      date = +date;
      return this.moment(date).format("DD-MM-YYYY, HH:mm:ss");
    },
  },
  created() {
    this.moment = moment;
  },
};
</script>

<style scoped>
.genContent {
  margin: auto;
  width: 90%;

  text-align: left;
}

table {
  margin: auto;
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 90%;
  margin-bottom: 2rem;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

.btn {
  float: right;
}

.btnUpdate {
  margin-left: 1rem;
  vertical-align: middle;
}

.icon {
  border: 1px solid transparent;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  padding: 0.5rem;
  float: right;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  z-index: 10;
}

.icon:hover {
  border: 1px solid #44bace;
  color: white;
  background-color: #44bace;
}

button {
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 12px;
  background-color: #44bace;
  cursor: pointer;
  color: #fff;
}

select,
input {
  width: 90%;
  border: grey;
  border-radius: 12px;
  padding: 0.5rem;
}
</style>
