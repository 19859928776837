<template>
  <div class="rootDiv">
    <font-awesome-icon class="icon" icon="plus" @click="close" />
    <h3>Inkoopformulier Toevoegen</h3>
    <div class="wrapper">
      <p class="left">Type Inkoop</p>
      <select v-model="typeInkoop">
        <option value="klant">Direct leveren bij de klant</option>
        <option value="magazijn">Leveren bij een magazijn</option>
      </select>
    </div>
    <div class="wrapper" v-if="typeInkoop === 'klant'">
      <p class="left">Klant Adres:</p>
      <select name="afleverLocatie" v-model="klantLocatie">
        <option v-for="k in klanten" :key="k.id" :value="k">
          {{ k.naam }} - {{ k.adres }}
        </option>
      </select>
    </div>
    <div class="wrapper" v-if="typeInkoop === 'magazijn'">
      <p class="left">Afleverlocatie:</p>
      <select
        name="afleverLocatie"
        v-model="afleverLocatie"
        @change="findMagazijn"
      >
        <option v-for="mag in magazijn" :key="mag.id" :value="mag.bedrijf">
          {{ mag.bedrijf }}
        </option>
      </select>
    </div>
    <div class="wrapper">
      <p class="left">Leverancier:</p>
      <select name="leverancier" v-model="leverancier">
        <option v-for="lev in leveranciers" :key="lev.id" :value="lev.naam">
          {{ lev.naam }}
        </option>
      </select>
    </div>
    <klant-inkoop
      v-if="klantLocatie"
      :leverancier="leverancier"
      :klant="klantLocatie"
      @close="close"
    ></klant-inkoop>
    <inkoop-list
      v-if="selectedMagazijn && leverancier"
      :leverancier="leverancier"
      :initialVal="selectedMagazijn"
      @close="close"
    ></inkoop-list>
  </div>
</template>

<script>
import InkoopList from "./InkoopList.vue";
import KlantInkoop from "./KlantInkoop.vue";
import {
  useLoadStock,
  useLoadMagazijn,
  useLoadLeveranciers,
  useLoadVerkoop,
} from "../fb.js";

export default {
  emits: ["close"],

  components: { InkoopList, KlantInkoop },
  setup() {
    let stock = useLoadStock();
    let magazijnen = useLoadMagazijn();
    let leveranciers = useLoadLeveranciers();
    let verkoop = useLoadVerkoop();
    return { stock, magazijnen, leveranciers, verkoop };
  },
  data() {
    return {
      afleverLocatie: "",
      klantLocatie: "",
      leverancier: "Natec",
      bestelnummer: "",
      factuurBedrag: 0,
      amount: 0,
      opmerking: "",
      bestelItems: [],
      selectedMagazijn: "",
      stockNaarMagazijn: [],
      typeInkoop: "",
    };
  },
  computed: {
    magazijn() {
      // const mag = this.magazijnen.filter((_magazijnen) => _magazijnen.showAlert === true);
      return this.magazijnen;
    },
    klanten() {
      return this.verkoop.filter((_verkoop) => _verkoop.klantLevering === true && !_verkoop.klantBesteld);
    },

    buttonText() {
      if (this.actief) {
        return "Zet dit magazijn op Inactief";
      } else {
        return "Zet dit magazijn op Actief";
      }
    },
    activityClass() {
      if (this.actief) {
        return "non-active";
      } else {
        return "active";
      }
    },
  },
  methods: {
    close() {
      // if (typeof(magazijnNaam) === "string") {
      //   this.$store.dispatch("setVerkoopToBesteld", magazijnNaam);
      // }
      this.$emit("close");
    },
    findMagazijn() {
      this.magazijn.forEach((val) => {
        if (val.bedrijf === this.afleverLocatie) {
          this.selectedMagazijn = val;
          if (this.selectedMagazijn.stock) {
            this.stockNaarMagazijn = this.selectedMagazijn.stock;
          }
        }
      });
    },
  },
};
</script>

<style scoped>
.non-active {
  background-color: red;
  border: none;
  color: white;
  border-radius: 12px;
  padding: 1rem 1.5rem;
  margin-bottom: 0.5rem;
  transition: 0.3s all ease-in-out;
  cursor: pointer;
}

.non-active:hover {
  background-color: rgb(251, 26, 26);
  box-shadow: rgba(0, 0, 0, 0.34) 0px 3px 8px;
}

.active {
  background-color: #60ab2d;
  border: none;
  color: white;
  border-radius: 12px;
  padding: 1rem 1.5rem;
  margin-bottom: 0.5rem;
  transition: 0.3s all ease-in-out;
  cursor: pointer;
}

.active:hover {
  background-color: #83c51c;
  box-shadow: rgba(0, 0, 0, 0.34) 0px 3px 8px;
}

.icon {
  transform: rotate(45deg);
  float: right;
  border: 1px solid grey;
  border-radius: 50%;
  padding: 0.5rem;
  transition: 0.3s all ease-in-out;
  cursor: pointer;
}

.icon:hover {
  border: 1px solid #44bace;
  background-color: #44bace;
  color: white;
  border-radius: 50%;
  padding: 0.5rem;
}

.wrapper {
  display: flex;
}

.left {
  width: 50%;
}
.rootDiv {
  background-color: #fff;
  padding: 2rem;
  border-radius: 12px;
  height: 100%;
}

input,
select {
  width: 80%;
  border: 1px solid #cccccc;
  padding: 1rem 1rem 1rem;
  border-radius: 0.4rem;
  background-color: #fff;
  transition: 1.5s all ease-in-out;
}

input:focus {
  width: 80%;
  border: 1px solid #44bace;
  padding: 1rem 1rem 1rem;
  border-radius: 0.4rem;
  background-color: lightgrey;
}
</style>
