<template>
  <div class="rootDiv">
    <div v-if="showLoading" class="loadingContainer">
      <div class="honeycomb">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div class="content" v-if="!showLoading">
      <h2>Installaties</h2>
      <h5> Huidige weeknummer:  {{ weekNr }}</h5>
      <div class="filterWrapper">
        <select :disabled="!!show" @change="filter" v-model="selectedStatus">
          <option default value="">Filter op verkoopstatus</option>
          <option value="">Alle</option>
          <option value="Nog niet gereserveerd">Nog niet gereserveerd</option>
          <option value="Gereserveerd">Gereserveerd</option>
          <option value="Inkoop">Inkoop</option>
          <option value="Geïnstalleerd">Geïnstalleerd</option>
          <option value="Verwijderd">Verwijderd</option>
        </select>
        <select :disabled="!!show" @change="filter" v-model="selectedMagazijn">
          <option default value="">Filter je magazijn</option>
          <option v-for="mag in magazijnen" :key="mag.id" :value="mag.bedrijf">
            {{ mag.bedrijf }}
          </option>
        </select>

        <select :disabled="!!show" @change="filter" v-model="selectedWeekNr">
          <option default value="">Filter per week</option>
          <option v-for="w in weekNumbers" :key="w" :value="w">
            Week {{ w }}
          </option>
        </select>
        <p style="margin: 0.5rem">van:</p>
        <input
          :disabled="!!show"
          placeholder="van Datum"
          type="date"
          @change="filter"
          v-model="filterFrom"
        />
        <p style="margin: 0.5rem">tot:</p>
        <input
          :disabled="!!show"
          placeholder="tot Datum"
          type="date"
          @change="filter"
          v-model="filterUntill"
        />
        <button v-if="!show" @click="resetFilter">Alle verkopen</button>
        <button v-if="!!show" @click="(show = false)">Sluit order </button>
      </div>
      <div v-if="!!show">
        <!-- <font-awesome-icon class="icon" icon="arrow-left" @click="(show = false)" /> -->
        <VerkoopView :instal="this.selectedId" >
        </VerkoopView>
      </div>

      <table v-if="!show">
        <tr>
          <th>Installatiedatum</th>
          <th>Naam</th>
          <th>Klantnummer</th>
          <th>Weeknummer</th>
          <th>Magazijn</th>
          <th>Status</th>
          <th>Opmerking</th>
          <th>Dubbele Verkoop</th>
        </tr>
        <!-- @click="reRoute(v.id)" -->
        <tr
          v-for="v in verkoop"
          :key="v.id"
          class="title"
          @click="(show = true), installatie(v.id)"
        >
          <td>{{ getDate(v.installatieDatum) }}</td>
          <td>{{ v.naam }}</td>
          <td>{{ v.klantNummer }}</td>
          <td>{{ v.weekNumber }}</td>
          <td>{{ v.magazijn }}</td>
          <td>{{ (v.status = verkoopStatus(v)) }}</td>
          <td>{{ getErrorText(v) }}</td>
          <td>{{ (v.double = doubleVerkoop(v)) }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { useLoadVerkoop, useLoadMagazijn, useLoadStock } from "../fb.js";
import VerkoopView from "./VerkoopView.vue";

export default {
  props: ["close"],
  components: {
    VerkoopView,
  },
  name: "App",
  setup() {
    let verkoopRecords = useLoadVerkoop();
    let magazijnen = useLoadMagazijn();
    let stock = useLoadStock();

    return { verkoopRecords, magazijnen, stock };
  },
  data() {
    return {
      edit: false,
      show: false,
      selectedVerkoop: "",
      selectedMagazijn: "",
      selectedWeekNr: "",
      handSelectedMagazijn: "",
      intialValue: "",
      aantal: 0,
      addedAantal: 0,
      selectedProduct: "",
      selectedStatus: "",
      verkoop: "",
      filterFrom: null,
      filterUntill: null,
      periodWeeks: 6,
      showLoading: false,
    };
  },
  computed: {
    weekNr() {
      let currentDate = new Date();
      let startDate = new Date(currentDate.getFullYear(), 0, 1);
      var days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));
      var weekNumber = Math.ceil(days / 7);
      return weekNumber;
    },
    weekNumbers() {
      const array = [];
      this.verkoopRecords.forEach((_verkoopRecords) => {
        if (!array.includes(_verkoopRecords.weekNumber)) {
          // if (_verkoopRecords.weekNumber >= this.weekNr - this.periodWeeks) {
          array.push(_verkoopRecords.weekNumber);
          // }
        }
      });
      return array;
    },
  },

  methods: {
    installatie(id) {
      return this.selectedId = id;
    },
    filter() {
      this.verkoop = this.verkoopRecords;
      if (this.selectedStatus) {
        this.verkoop = this.verkoop.filter(
          (val) => val.status === this.selectedStatus
        );
      }
      if (this.selectedMagazijn) {
        this.verkoop = this.verkoop.filter(
          (val) => val.magazijn === this.selectedMagazijn
        );
      }
      if (this.selectedWeekNr) {
        let year = new Date().getFullYear().valueOf();
        this.verkoop = this.verkoop.filter(
          (val) =>val.jaar === year && val.weekNumber === this.selectedWeekNr
          // (val) => val.weekNumber === this.selectedWeekNr
        );
      }
      if (this.filterFrom && this.filterUntill) {
        const f = new Date(this.filterFrom).valueOf();
        const g = new Date(this.filterUntill).valueOf();
        this.verkoop = this.verkoop.filter((val) => val.installatieDatum >= f && val.installatieDatum <= g);
      }
      return this.verkoop;
    },
    filterStatus() {
      if (this.selectedStatus) {
        this.verkoop = this.verkoopRecords.filter(
          (val) => val.status === this.selectedStatus
        );
      } else {
        this.verkoop = this.verkoopRecords;
      }
    },
    verkoopStatus(verkoop) {
      if (verkoop.verwijderd) {
        return "Verwijderd";
      } else if (verkoop.afgerond) {
        return "Geïnstalleerd";
      } else if (verkoop.inkoopOrdered) {
        return "Inkoop Besteld";
      } else if (verkoop.gereserveerd) {
        return "Gereserveerd";
      } else {
        return "Nog niet gereserveerd";
      }
    },
    doubleVerkoop(verkoop) {
      const rec = this.verkoopRecords.find(
        (val) =>
          val.id !== verkoop.id &&
          val.klantNummer === verkoop.klantNummer &&
          !val.verwijderd
      );
      if (rec) {
        return "DUBBELE VERKOOP";
      }
    },
    getErrorText(verkoop) {
      if (verkoop.mutatie) {
        return "MUTATIE NOG NIET VERWERKT!";
      }
    },
    filterWeekNr() {
      this.verkoop = this.verkoopRecords.filter(
        (val) => val.weekNumber === this.selectedWeekNr
      );
    },

    reRoute(id) {
      this.$router.push(`verkoop/${id}`);
    },
    filterMagazijn() {
      this.verkoop = this.verkoopRecords.filter(
        (val) => val.magazijn === this.selectedMagazijn
      );
    },
    filterDate() {
      let start = new Date(this.filterFrom).valueOf() || new Date().valueOf();
      let end =
        new Date(this.filterUntill).valueOf() ||
        new Date().valueOf() + 31556952000;

      this.verkoop = this.verkoop.filter(
        (val) => val.installatieDatum >= start && val.installatieDatum <= end
      );
    },
    resetFilter() {
      this.verkoop = this.verkoopRecords;
      this.selectedMagazijn = "";
      this.selectedWeekNr = "";
      this.selectedStatus = "";
      this.filterFrom = null;
      this.filterUntill = null;
      this.moment = moment;
    const d = new Date();
    d.setHours(0, 0, 0, 0);
    const e = new Date(d).valueOf();
      this.verkoop = this.verkoopRecords.filter(
        (val) => !val.verwijderd && val.installatieDatum >= e
      );
    },
    productToevoegen() {
      const doc = {
        aantal: this.addedAantal,
        artikelNummer: this.selectedProduct.artikelNummer,
        naam: this.selectedProduct.id,
        id: this.selectedProduct.id,
        status: "Niet gereserveerd",
        type: this.selectedProduct.typeHoofdProduct,
      };
      this.selectedVerkoop.gereserveerd = false;
      this.selectedVerkoop.bestelItems.push(doc);
      this.selectedProduct = "";
      this.addedAantal = 0;
    },
    selectMagazijn(value) {
      this.selectedVerkoop.magazijn = value.bedrijf;
      this.$store.dispatch("setVerkoopChanges", this.selectedVerkoop);
    },
    openVerkoop(v) {
      this.selectedVerkoop = v;
      this.magazijnen.forEach((magazijn) => {
        if (this.selectedVerkoop.magazijn === magazijn.bedrijf) {
          this.selectedMagazijn = magazijn;
        }
      });
    },
    update(i) {
      const changedItem = i;
      let difference = this.aantal - i.aantal;
      i.aantal = this.aantal;
      if (!this.selectedVerkoop.gereserveerd) {
        if (changedItem.zonnepaneel) {
          this.selectedVerkoop.bestelItems.forEach((val) => {
            if (val.type === "variabel" && val.naam !== changedItem.naam) {
              val.aantal = changedItem.aantal;
            }
          });
        }
      }
      this.$store.dispatch("setVerkoopChanges", this.selectedVerkoop);
      if (this.selectedVerkoop.gereserveerd) {
        this.selectedMagazijn.stock.forEach((val) => {
          if (val.productNaam === i.naam) {
            val.adminStock = val.adminStock - difference;
          }
        });
        const d = new Date().valueOf();
        const log2 = {
          afterValue: this.selectedMagazijn,
          collection: "Magazijnen",
          recordId: this.selectedMagazijn.id,
          type: "administratief",
          timeStamp: d,
        };
        const doc2 = {
          id: this.selectedMagazijn.id,
          content: this.selectedMagazijn.stock,
        };

        this.$store.dispatch("saveStock", doc2);
        this.$store.dispatch("createLog", log2);
      }
    },


    async afronden() {
      this.selectedVerkoop.afgerond = true;
      const items = this.selectedVerkoop.bestelItems;
      items.forEach((_items) => {
        this.selectedMagazijn.stock.forEach((_stock) => {
          if (
            _stock.productNaam === _items.naam &&
            _items.status === "Gereserveerd"
          ) {
            _stock.fysiekStock = _stock.fysiekStock + _items.aantal * -1;
          }
        });
      });
      setTimeout(() => {
        const d = new Date().valueOf();
        const log2 = {
          afterValue: this.selectedMagazijn,
          collection: "Magazijnen",
          recordId: this.selectedMagazijn.id,
          type: "fysiek",
          timeStamp: d.toString(),
        };
        this.$store.dispatch("createLog", log2);
        this.$store.dispatch("saveStock", {
          id: this.selectedMagazijn.id,
          content: this.selectedMagazijn.stock,
        });
        const log = {
          afterValue: this.selectedVerkoop,
          collection: "Verkoop",
          recordId: this.selectedVerkoop.id,
          type: "edit",
          timeStamp: d.toString(),
        };
        this.$store.dispatch("createLog", log);
        this.$store.dispatch("setVerkoopChanges", this.selectedVerkoop);
      }, 0);
    },

    async verwerken(type = 0) {
      if (type === 0) {
        this.selectedVerkoop.gereserveerd = true;
      } else {
        this.selectedVerkoop.gereserveerd = false;
      }
      const items = this.selectedVerkoop.bestelItems;
      let emptyStock = false;
      const array = [];
      items.forEach((_items) => {
        if (this.selectedMagazijn.stock) {
          this.selectedMagazijn.stock.forEach((_stock) => {
            if (_stock.productNaam === _items.naam) {
              if (type === 0) {
                _stock.adminStock = _stock.adminStock + _items.aantal * -1;
              } else {
                _stock.adminStock = _stock.adminStock + _items.aantal;
              }
              if (_items.aantal < 0 || type === 1) {
                _items.status = "Niet gereserveerd";
              } else {
                _items.status = "Gereserveerd";
              }
            }
          });
        } else {
          emptyStock = true;
          const doc = {
            adminStock: _items.aantal * -1,
            fysiekStock: 0,
            productNaam: _items.naam,
            saved: true,
            bijgewerkt: false,
          };
          _items.status = "Gereserveerd";

          array.push(doc);
        }
      });

      setTimeout(() => {
        items.forEach((_items) => {
          if (_items.status === "Niet gereserveerd" && type === 0) {
            const doc = {
              adminStock: _items.aantal * -1,
              fysiekStock: 0,
              productNaam: _items.naam,
              saved: true,
              bijgewerkt: false,
            };
            _items.status = "Gereserveerd";
            this.selectedMagazijn.stock.push(doc);
          }
        });
        setTimeout(() => {
          if (emptyStock === true) {
            this.selectedMagazijn.stock = array;
          }
          const d = new Date().valueOf();
          const log2 = {
            afterValue: this.selectedMagazijn,
            collection: "Magazijnen",
            recordId: this.selectedMagazijn.id,
            type: "administratief",
            timeStamp: d.toString(),
          };
          this.$store.dispatch("createLog", log2);
          this.$store.dispatch("saveStock", {
            id: this.selectedMagazijn.id,
            content: this.selectedMagazijn.stock,
          });
          if (this.selectedVerkoop.annulering) {
            this.selectedVerkoop.bestelItems.forEach((val) => {
              val.aantal = val.aantal * -1;
            });
          }
          const log = {
            afterValue: this.selectedVerkoop,
            collection: "Verkoop",
            recordId: this.selectedVerkoop.id,
            type: "edit",
            timeStamp: d.toString(),
          };
          this.$store.dispatch("createLog", log);
          this.$store.dispatch("setVerkoopChanges", this.selectedVerkoop);
        }, 0);
      }, 0);
    },

    getDate(date) {
      date = +date;
      return this.moment(date).format("DD-MM-YYYY");
    },
    closing() {
      this.show = false;
    },
  },
  created() {
    this.moment = moment;
    const d = new Date();
    d.setHours(0, 0, 0, 0);
    const e = new Date(d).valueOf();
    this.showLoading = true;
    setTimeout(() => {
      this.verkoop = this.verkoopRecords.filter(
        (val) => !val.verwijderd && val.installatieDatum >= e
        // (val) => !val.verwijderd && val.magazijn === "Easy Energy" && val.installatieDatum >= e
      ); // VOOR DE TESTWAARDE STAAT HET MAGAZIJN NU OP EASY ENERGY. DEZE STUKJE KAN ER VOOR DE LIVEGANG UIT!
      this.showLoading = false;
    }, 2500);
  },
};
</script>

<style scoped>

.rootDiv {
  padding: 1rem;
  background-color: #fff;
}

.transfer {
  background-color: #fff;
  border: 2px solid #44bace;
  padding: 1rem 2rem;
  color: #44bace;
  border-radius: 12px;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
}

.transfer:hover {
  background-color: #44bace;
  border: 2px solid #44bace;
  color: white;
}

button + button {
  margin-right: 1rem;
}

.filterWrapper {
  display: flex;
}

.content {
  padding: 2rem;
}

.alert {
  background-color: orange;
  /* opacity: 0.6; */
}

.iconBack {
  transform: rotate(45deg);
  padding: 0.5rem;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid black;
  float: right;
  cursor: pointer;
}

.titleText {
  font-size: 0.9rem;
  margin-left: 2rem;
}
.title {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.title:hover {
  background-color: #44bace;
}

.description {
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  padding: 5px;
}

.active {
  background-color: #44bace;
  color: #fff;
}

.genContent {
  margin: auto;
  width: 90%;

  text-align: left;
}

button + button {
  margin-left: 2rem;
}

.btn {
  float: right;
}

.btnUpdate {
  margin-left: 1rem;
  vertical-align: middle;
}

.icon {
  border: 1px solid transparent;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  padding: 0.5rem;
  float: right;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  z-index: 10;
}

.icon:hover {
  border: 1px solid #44bace;
  color: white;
  background-color: #44bace;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
}

.dialogView {
  margin: auto;
  z-index: 15;
  /* left: 60%; */
  /* right: auto; */
}

dialog {
  max-width: 80rem;
  max-height: 90vh;
  /* z-index: 100; */
  border: none;
  overflow-y: auto;
  background-color: transparent;
}

button {
  border: none;
  height: 2.3rem;
  padding: 0.5rem;
  border-radius: 12px;
  background-color: #44bace;
  cursor: pointer;
  color: #fff;
}

.dialog-enter-from,
.dialog-leave-to {
  opacity: 0;
  transform: scale(0.8);
}

.dialog-enter-active {
  transition: all 0.3s ease-out;
}

.dialog-leave-active {
  transition: all 0.3 ease-in;
}

.dialog-enter-to,
.dialog-leave-from {
  opacity: 1;
  transform: scale(1);
}
/* select.disable {
  pointer-events: none;
  opacity: 0.4;
  cursor: not-allowed;
}
input.disable {
  pointer-events: none;
  opacity: 0.4;
  cursor: not-allowed;
} */

select,
input {
  border: 1px solid black;
  border-radius: 12px;
  padding: 0.5rem;
  margin-bottom: 1rem;
  margin-right: 2rem;
  cursor: pointer;
  /* cursor: not-allowed; */
  pointer-events: all;
  /* pointer-events: none; */
}

table {
  margin: auto;
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 2rem;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

/* honeycomb css */

.loadingContainer {
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;
  overflow: hidden;
  height: 100vh;
  width: 100%;
}


/**
  * Honeycomb
  *
  * @author jh3y - jheytompkins.com
*/
@-webkit-keyframes honeycomb {
  0%,
  20%,
  80%,
  100% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  30%,
  70% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes honeycomb {
  0%,
  20%,
  80%,
  100% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  30%,
  70% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.honeycomb {
  height: 24px;
  position: relative;
  width: 24px;
}
.honeycomb div {
  -webkit-animation: honeycomb 2.1s infinite backwards;
  animation: honeycomb 2.1s infinite backwards;
  background: lightgrey;
  height: 12px;
  margin-top: 6px;
  position: absolute;
  width: 24px;
}
.honeycomb div:after,
.honeycomb div:before {
  content: "";
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  position: absolute;
  left: 0;
  right: 0;
}
.honeycomb div:after {
  top: -6px;
  border-bottom: 6px solid lightgrey;
}
.honeycomb div:before {
  bottom: -6px;
  border-top: 6px solid lightgrey;
}
.honeycomb div:nth-child(1) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  left: -28px;
  top: 0;
}
.honeycomb div:nth-child(2) {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
  left: -14px;
  top: 22px;
}
.honeycomb div:nth-child(3) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
  left: 14px;
  top: 22px;
}
.honeycomb div:nth-child(4) {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
  left: 28px;
  top: 0;
}
.honeycomb div:nth-child(5) {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
  left: 14px;
  top: -22px;
}
.honeycomb div:nth-child(6) {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
  left: -14px;
  top: -22px;
}
.honeycomb div:nth-child(7) {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
  left: 0;
  top: 0;
}
</style>
