<template>
  <div class="rootDiv">
    <font-awesome-icon class="icon" icon="plus" @click="close" />
    <h3>Transfer</h3>

    <div class="wrapper">
      <p class="left">Van locatie:</p>
      <p class="right">{{ selectedMagazijn.bedrijf }}</p>
    </div>
    <div class="wrapper">
      <p class="left">Naar magazijn:</p>
      <select
        name="afleverLocatie"
        v-model="receivingMagazijn"
        @change="findMagazijn"
      >
        <option v-for="mag in magazijn" :key="mag.id" :value="mag">
          {{ mag.bedrijf }}
        </option>
      </select>
    </div>
    <transfer-list
      v-if="receivingMagazijn && selectedMagazijn"
      :receivingMagazijn="receivingMagazijn"
      :selectedMagazijn="selectedMagazijn"
      @close="close"
    ></transfer-list>
  </div>
</template>

<script>
import TransferList from "./TransferList.vue";
import { useLoadMagazijn } from "../fb.js";

export default {
  emits: ["close"],
  props: ["selectedMagazijn"],

  components: { TransferList },
  setup() {
    let magazijn = useLoadMagazijn();
    return { magazijn };
  },
  data() {
    return {
      receivingMagazijn: "",
      afleverLocatie: "",
      leverancier: "",
      bestelnummer: "",
      factuurBedrag: 0,
      amount: 0,
      opmerking: "",
      bestelItems: [],
      stockNaarMagazijn: [],
    };
  },
  computed: {
    buttonText() {
      if (this.actief) {
        return "Zet dit magazijn op Inactief";
      } else {
        return "Zet dit magazijn op Actief";
      }
    },
    activityClass() {
      if (this.actief) {
        return "non-active";
      } else {
        return "active";
      }
    },
  },
  methods: {

    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.non-active {
  background-color: red;
  border: none;
  color: white;
  border-radius: 12px;
  padding: 1rem 1.5rem;
  margin-bottom: 0.5rem;
  transition: 0.3s all ease-in-out;
  cursor: pointer;
}

.non-active:hover {
  background-color: rgb(251, 26, 26);
  box-shadow: rgba(0, 0, 0, 0.34) 0px 3px 8px;
}

.active {
  background-color: #60ab2d;
  border: none;
  color: white;
  border-radius: 12px;
  padding: 1rem 1.5rem;
  margin-bottom: 0.5rem;
  transition: 0.3s all ease-in-out;
  cursor: pointer;
}

.active:hover {
  background-color: #83c51c;
  box-shadow: rgba(0, 0, 0, 0.34) 0px 3px 8px;
}

.icon {
  transform: rotate(45deg);
  float: right;
  border: 1px solid grey;
  border-radius: 50%;
  padding: 0.5rem;
  transition: 0.3s all ease-in-out;
  cursor: pointer;
}

.icon:hover {
  border: 1px solid #44bace;
  background-color: #44bace;
  color: white;
  border-radius: 50%;
  padding: 0.5rem;
}

.wrapper {
  display: flex;
}

.left {
  width: 30%;
}
.rootDiv {
  background-color: #fff;
  padding: 2rem;
  border-radius: 12px;
}

input,
select {
  width: 60%;
  border: 1px solid #cccccc;
  padding: 1rem 1rem 1rem;
  border-radius: 0.4rem;
  background-color: #fff;
  transition: 1.5s all ease-in-out;
}

input:focus {
  width: 60%;
  border: 1px solid #44bace;
  padding: 1rem 1rem 1rem;
  border-radius: 0.4rem;
  background-color: lightgrey;
}
</style>
