<template>
  <div class="rootDiv">
    <font-awesome-icon class="icon" icon="plus" @click="close" />
    <h3>Edit</h3>
    <div class="wrapper" v-if="actief != null">
      <p class="left">Actief:</p>
      <button @click="actief = !actief" :class="activityClass">
        {{ buttonText }}
      </button>
    </div>
    <div class="wrapper" v-if="magazijn.actief != actief">
      <p class="left"></p>
      <small
        >Om deze aanpassing te voltooien, moet je op de wijziging
        Opslaan.</small
      >
    </div>
    <div class="wrapper">
      <p class="left">Naam:</p>
      <input type="text" v-model="bedrijf" />
    </div>
    <div class="wrapper">
      <p class="left">Contactpersoon:</p>
      <input type="text" v-model="contactPersoon" />
    </div>
    <div class="wrapper">
      <p class="left">Email 1 :</p>
      <input type="text" v-model="email1" />
    </div>
    <div class="wrapper">
      <p class="left">Email 2 :</p>
      <input type="text" v-model="email2" />
    </div>
    <div class="wrapper">
      <p class="left">Telefoonnummer:</p>
      <input type="text" v-model="telefoonNummer" />
    </div>
    <div class="wrapper">
      <p class="left">Adres:</p>
      <input type="text" v-model="adres" />
    </div>
    <div class="wrapper">
      <p class="left">Postcode:</p>
      <input type="text" v-model="postCode" />
    </div>
    <div class="wrapper">
      <p class="left">Stad:</p>
      <input type="text" v-model="stad" />
    </div>
    <div class="wrapper">
      <p class="left">Agenda naam :</p>
      <input type="text" v-model="agendaNaam" />
    </div>
    <div class="wrapper">
      <p class="left">Bestellen op :</p>
      <input type="text" v-model="bestellen" />
    </div>
    <div class="wrapper">
      <p class="left">Levering op :</p>
      <input type="text" v-model="levering" />
    </div>
    <div class="wrapper">
      <p class="left">Voorraadcheck:</p>
      <input type="text" v-model="voorraadCheck" />
    </div>
    <div class="wrapper">
      <p class="left">Opmerking:</p>
      <input type="text" v-model="opmerking" />
    </div>
    <button class="btn" @click="updateMagazijn">Opslaan</button>
  </div>
</template>

<script>
export default {
  props: ["magazijn"],
  data() {
    return {
      bedrijf: "",
      contactPersoon: "",
      email1: "",
      email2: "",
      telefoonNummer: "",
      adres: "",
      postCode: "",
      stad: "",
      agendaNaam: "",
      bestellen: "",
      levering: "",
      voorraadCheck: "",
      opmerking: "",
      actief: null,
    };
  },
  computed: {
    buttonText() {
      if (this.actief) {
        return "Zet dit magazijn op Inactief";
      } else {
        return "Zet dit magazijn op Actief";
      }
    },
    activityClass() {
      if (this.actief) {
        return "non-active";
      } else {
        return "active";
      }
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    updateMagazijn() {
      let status = true;
      if (this.magazijn) {
        status = this.actief;
      }

      const d = new Date().valueOf();

      const doc = {
        bedrijf: this.bedrijf,
        contactPersoon: this.contactPersoon,
        email1: this.email1,
        email2: this.email2,
        telefoonNummer: this.telefoonNummer,
        adres: this.adres,
        postCode: this.postCode,
        stad: this.stad,
        stock: [],
        agendaNaam: this.agendaNaam,
        bestellen: this.bestellen,
        levering: this.levering,
        voorraadCheck: this.voorraadCheck,
        opmerking: this.opmerking,
        actief: status,
      };
      
      if (this.magazijn) {
        if (this.magazijn.stock) {
          doc.stock = this.magazijn.stock;
        }
        doc.id = this.magazijn.id;
        const log = {
          beforeValue: this.magazijn,
          afterValue: doc,
          collection: "Magazijnen",
          recordId: doc.id,
          type: "edit",
          timeStamp: d,
        };
        this.$store.dispatch("createLog", log);
        this.$store.dispatch("updateMagazijn", doc);
      } else {
        this.$store.dispatch("addMagazijn", doc);
        const log = {
          beforeValue: "",
          afterValue: doc,
          collection: "Magazijnen",
          recordId: "",
          type: "create",
          timeStamp: d,
        };
        this.$store.dispatch("createLog", log);
      }
      setTimeout(() => {
        this.close();
      }, 500);
    },
  },
  created() {
    if (this.magazijn) {
      this.bedrijf = this.magazijn.bedrijf;
      this.contactPersoon = this.magazijn.contactPersoon;
      this.email1 = this.magazijn.email1;
      this.email2 = this.magazijn.email2;
      this.telefoonNummer = this.magazijn.telefoonNummer;
      this.adres = this.magazijn.adres;
      this.postCode = this.magazijn.postCode;
      this.stad = this.magazijn.stad;
      this.agendaNaam = this.magazijn.agendaNaam;
      this.bestellen = this.magazijn.bestellen;
      this.levering = this.magazijn.levering;
      this.voorraadCheck = this.magazijn.voorraadCheck;
      this.opmerking = this.magazijn.opmerking;
      this.actief = this.magazijn.actief;
    }
  },
};
</script>

<style scoped>
.non-active {
  background-color: red;
  border: none;
  color: white;
  border-radius: 12px;
  padding: 1rem 1.5rem;
  margin-bottom: 0.5rem;
  transition: 0.3s all ease-in-out;
  cursor: pointer;
}

.non-active:hover {
  background-color: rgb(251, 26, 26);
  box-shadow: rgba(0, 0, 0, 0.34) 0px 3px 8px;
}

.active {
  background-color: #60ab2d;
  border: none;
  color: white;
  border-radius: 12px;
  padding: 1rem 1.5rem;
  margin-bottom: 0.5rem;
  transition: 0.3s all ease-in-out;
  cursor: pointer;
}

.active:hover {
  background-color: #83c51c;
  box-shadow: rgba(0, 0, 0, 0.34) 0px 3px 8px;
}

.icon {
  transform: rotate(45deg);
  float: right;
  border: 1px solid grey;
  border-radius: 50%;
  padding: 0.5rem;
  transition: 0.3s all ease-in-out;
  cursor: pointer;
}

.icon:hover {
  border: 1px solid #44bace;
  background-color: #44bace;
  color: white;
  border-radius: 50%;
  padding: 0.5rem;
}

.btn {
  cursor: pointer;
  margin-top: 2rem;
  border: none;
  padding: 0.5rem 1rem;
  color: #fff;
  background-color: #44bace;
  border-radius: 12px;
  border: 2px solid transparent;
  transition: 0.3s all ease-in-out;
}

.btn:hover {
  border: 2px solid #358391;
}

.wrapper {
  display: flex;
}

.left {
  width: 30%;
}
.rootDiv {
  background-color: #fff;
  padding: 2rem;
  border-radius: 12px;
  overflow-y: auto;

}

input {
  width: 60%;
  border: 1px solid #cccccc;
  padding: 1rem 1rem 1rem;
  border-radius: 0.4rem;
  background-color: #fff;
  transition: 1.5s all ease-in-out;
}

input:focus {
  width: 60%;
  border: 1px solid #44bace;
  padding: 1rem 1rem 1rem;
  border-radius: 0.4rem;
  background-color: lightgrey;
}
</style>
