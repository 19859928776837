<template>
  <div class="rootDiv">
    <div class="wrapper">
      <div class="genContent">
        <p style="color: red; font-weight: 700" v-if="showError">
          Vul een verwachte levertijd voor het item dat in backorder staat!
        </p>
        <button
          v-if="
            !selectedValue.verwerkt &&
            selectedValue.afleverLocatie !== 'klant' &&
            !showSetButton"
          class="btn"
          @click="verwerken()"
        >
          Verwerken
        </button>
        <button class="btn setButton" v-if="showSetButton" @click="opslaan">
          Opslaan
        </button>

        <p>Besteldatum: {{ getDate(selectedValue.bestelDatum) }}</p>
        <p
          class="editLink"
          v-if="selectedValue.leveringsDatum"
          @click="selectedValue.leveringsDatum = null"
        >
          Leveringsdatum:
          {{
            (selectedValue.leveringsDatumFormat = getDate(
              selectedValue.leveringsDatum
            ))
          }}
        </p>
        <p v-else>
          Leveringsdatum:
          <input
            class="levering"
            type="date"
            v-model="selectedValue.leveringsDatum"
            @change="setDates"
          />
        </p>
        <p v-if="!selectedValue.type">
          Locatie Aflevering: {{ selectedValue.afleverLocatie }}
        </p>
        <div v-if="!selectedValue.type && selectedValue.linkedVerkoop">
          Gelinkte installaties:
          <i
            style="
              width: fit-content;
              color: blue;
              text-decoration: underline;
              cursor: pointer;
              display: block;
            "
            @click="reRoute(`/verkoop/${l}`)"
            v-for="l in selectedValue.linkedVerkoop"
            :key="l"
            >{{ l }}</i
          >
        </div>
        <div v-else>
          <p>
            Naam:
            <i
              style="color: blue; text-decoration: underline; cursor: pointer"
              @click="reRoute(`/verkoop/${selectedValue.klantLink}`)"
              >{{ selectedValue.klantNaam }}</i
            >
          </p>
          <p style="color: red; font-weight: 700">
            Klant Aflevering: {{ selectedValue.afleverLocatie }}
          </p>
          <p>Telefoonnummer:</p>

          <p>Magazijn voor overschot: {{ selectedValue.klantMagazijn }}</p>
        </div>
        <p>Bestelnummer intern: {{ selectedValue.bestelnummer }}</p>
        <p>
          Bestelnummer leverancier:
          {{ selectedValue.bestelnummerLeverancier }}
        </p>
        <p v-if="selectedValue.vonNummer">
          VON nummer: {{ selectedValue.vonNummer }}
        </p>
        <p v-else>
          VON nummer:
          <input
            style="width: 10rem; border: 1px solid grey"
            @change="showVONButton = true"
            type="text"
            v-model="vonNummer"
          />
          <button v-if="showVONButton" @click="setVON">Opslaan</button>
        </p>

        <p>Factuurbedrag: €{{ selectedValue.factuurBedrag }}</p>
        <p class="opmerking" v-if="selectedValue.opmerking">
          Opmerking: {{ selectedValue.opmerking }}
        </p>
        <div v-if="!selectedValue.verwerkt">
          <p>
            Alles Geleverd:
            <input
              style="width: 3%"
              type="checkbox"
              v-model="volledigeLevering"
              @change="updateRec()"
            />
          </p>
          <button
            @click="opslaan"
            v-if="volledigeLevering && selectedValue.type"
          >
            Ok
          </button>
        </div>
      </div>
      <table>
        <tr>
          <th style="width: 10%">Artikelnummer</th>
          <th style="width: 25%">Naam</th>
          <th style="width: 5%">Aantal</th>
          <th style="width: 15%">Verwachte Levertijd</th>
          <th style="width: 25%">Status</th>
          <th v-if="selectedValue.type === 'klant'" style="width: 5%">Nodig</th>
          <th v-if="selectedValue.type === 'klant'" style="width: 35%">
            Overschot terug naar magazijn
          </th>
        </tr>
        <tr v-for="(item, index) of selectedValue.bestelItems" :key="item.naam">
          <td>{{ item.artikelNummer }}</td>
          <td>{{ item.naam }}</td>
          <td>{{ item.aantal }}</td>
          <td
            class="editLink"
            v-if="item.levertijd"
            @click="item.levertijd = null"
          >
            {{ getDate(item.levertijd) }}
          </td>
          <td v-if="!item.levertijd">
            <input
              @change="item.levertijd = setDate(item.levertijd)"
              type="date"
              v-model="item.levertijd"
            />
          </td>
          <td v-if="item.status === 'Verwerkt' || selectedValue.verwerkt">
            {{ item.status }}
          </td>
          <td v-else-if="item.status !== 'Deels geleverd'">
            <select
              @change="checkStatus(item, index)"
              name="item.status"
              v-model="item.status"
            >
              <option value="Geleverd">Geleverd</option>
              <option value="Niet leverbaar">Niet leverbaar</option>
              <option v-if="item.aantal > 1" value="Deels geleverd">
                Deels geleverd
              </option>
              <option value="Backorder">Backorder</option>
            </select>
          </td>
          <td v-else-if="item.status === 'Deels geleverd'">
            Hoeveel geleverd?<input
              style="width: 30%; margin: 0 2rem; border: 1px solid black"
              type="number"
              v-model="item.deelAantal"
            />
            <button @click="splitItem(item, index)">Update</button>
          </td>
          <td v-if="selectedValue.type">{{ item.nodig }}</td>
          <td v-if="selectedValue.type">{{ item.overschot }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { useLoadMagazijn, useLoadInkoop, useLoadVerkoop } from "../fb.js";

export default {
  data() {
    return {
      selectedValue: "",
      vonNummer: "",
      startVal: "",
      volledigeLevering: false,
      initValMagazijn: "",
      showButton: false,
      showSetButton: false,
      showVONButton: false,
      showError: false,
      allItemsChecked: false,
    };
  },

  setup() {
    let inkoop = useLoadInkoop();
    let magazijnen = useLoadMagazijn();
    let verkoop = useLoadVerkoop();
    return { magazijnen, inkoop, verkoop };
  },
  computed: {
    storedItem() {
      return this.$store.getters.getItem;
    },
  },
  methods: {

    setVON() {
      this.selectedValue.vonNummer = this.vonNummer;
      this.$store.dispatch("updateInkoop", this.selectedValue);
    },
    setDates() {
      this.selectedValue.leveringsDatum = this.moment(
        this.selectedValue.leveringsDatum
      ).format("DD-MM-YYYY");
      this.selectedValue.bestelItems.forEach((_items) => {
        _items.levertijd = this.selectedValue.leveringsDatum;
      });
      this.showSetButton = true;
    },
    reRoute(path) {
      this.$router.push(path);
    },
    checkStatus(item, index) {
      this.showSetButton = true;
      if (item.status === "Backorder") {
        this.selectedValue.bestelItems[index].levertijd =
          "Vul de verwachte levertijd in!";
      }
    },
    setDate(date = null) {
      if (date) {
        date = new Date(date).valueOf();
        date = this.moment(date).format("DD-MM-YYYY");
        this.showSetButton = true;
        return date;
      } else {
        this.selectedValue.leveringsDatum = new Date(
          this.selectedValue.leveringsDatum
        ).valueOf();
        this.selectedValue.bestelItems.forEach((_items) => {
          _items.levertijd = this.selectedValue.leveringsDatum;
        });
      }
      this.showSetButton = true;
    },
    checkVerkoop(verkoop, item) {
      const v = this.verkoop.find((_verkoop) => _verkoop.id === verkoop);

      const foundItem = v.bestelItems.find((_item) => _item.naam === item.naam);
      if (foundItem) {
        if (!v.backOrderItems) {
          v.backOrderItems = [];
        } else if (v.backOrderItems.length > 0) {
          const index = v.backOrderItems.findIndex(
            (_items) => _items.naam === item.naam
          );
          if (index !== -1) {
            v.backOrderItems.splice(index, 1);
          }
        }

        if (item.status !== "Geleverd" && item.status !== "Verwerkt") {
          const doc = {
            naam: foundItem.naam,
            levertijd: item.levertijd,
            status: item.status,
            bestelID: this.selectedValue.id,
          };
          v.backOrderItems.push(doc);
        }
        this.$store.dispatch("setVerkoopChanges", v);
      }
    },
    opslaan() {
      let counter = 0;
      const backOrder = this.selectedValue.bestelItems.find(
        (_items) => _items.status === "Backorder"
      );

      if (!backOrder) {
        this.selectedValue.itemsInBackorder = false;
      }
      const noDate = this.selectedValue.bestelItems.find(
        (_items) => _items.levertijd === "Vul de verwachte levertijd in!"
      );
      if (!noDate) {
        this.selectedValue.bestelItems.forEach((item) => {
          if (item.status === "Geleverd") {
            item.levertijd = this.selectedValue.leveringsDatumFormat;
            // item.status = "Verwerkt";
            counter = counter + 1;
            this.selectedValue.linkedVerkoop.forEach((link) => {
              this.checkVerkoop(link, item);
            });
          } else if (
            item.status === "Verwerkt" ||
            item.status === "Niet leverbaar"
          ) {
            counter = counter + 1;
            if (item.status === "Niet leverbaar") {
              this.selectedValue.linkedVerkoop.forEach((link) => {
                this.checkVerkoop(link, item);
              });
            }
          }
          if (item.status === "Backorder") {
            this.showError = false;
            this.selectedValue.itemsInBackorder = true;
            this.selectedValue.linkedVerkoop.forEach((link) => {
              this.checkVerkoop(link, item);
            });
          } else {
            this.showError = true;
          }
        });
        if (counter === this.selectedValue.bestelItems.length) {
          this.selectedValue.volledigGeleverd = true;
          this.selectedValue.verwerkt = true;
          this.selectedValue.itemsInBackorder = false;
        }
        const d = new Date().valueOf();
        const log = {
          afterValue: this.selectedValue,
          collection: "Inkoop",
          recordId: this.selectedValue.id,
          type: "edit",
          timeStamp: d.toString(),
        };
        this.$store.dispatch("createLog", log);
        this.$store.dispatch("updateInkoop", this.selectedValue);
        this.showError = false;

        this.showSetButton = false;
      } else {
        this.showError = true;
      }
    },
    splitItem(item, index) {
      if (item.deelAantal) {
        const doc = {
          aantal: item.deelAantal,
          artikelNummer: item.artikelNummer,
          status: "Geleverd",
          naam: item.naam,
        };

        const doc2 = {
          aantal: item.aantal - item.deelAantal,
          artikelNummer: item.artikelNummer,
          status: "Backorder",
          naam: item.naam,
        };

        this.selectedValue.bestelItems.splice(index, 1);
        this.selectedValue.bestelItems.push(doc);
        this.selectedValue.bestelItems.push(doc2);
      }
    },

    async verwerken() {
      const arrayToConvert = [];
      this.selectedValue.bestelItems.forEach((item) => {
        if (item.status === "Geleverd" || item.status === "Niet leverbaar") {
          this.selectedValue.linkedVerkoop.forEach((link) => {
            this.checkVerkoop(link, item);
          });
          arrayToConvert.push(item);
        }
      });

      const magazijnFilter = this.magazijnen.filter(
        (val) => val.bedrijf === this.selectedValue.afleverLocatie
      );
      let magazijn = magazijnFilter[0];

      arrayToConvert.forEach((_arrayToConvert) => {
        magazijn.stock.forEach((_stock) => {
          if (
            _stock.productNaam === _arrayToConvert.naam &&
            _arrayToConvert.status === "Geleverd"
          ) {
            _stock.fysiekStock = _stock.fysiekStock + _arrayToConvert.aantal;
          } else if (
            _stock.productNaam === _arrayToConvert.naam &&
            _arrayToConvert.status === "Niet leverbaar"
          ) {
            _stock.adminStock = _stock.adminStock - _arrayToConvert.aantal;
          }
        });
      });

      setTimeout(() => {
        let counter = 0;
        this.selectedValue.bestelItems.forEach((item) => {
          if (item.status === "Geleverd") {
            item.status = "Verwerkt";
            counter = counter + 1;
          } else if (
            item.status === "Verwerkt" ||
            item.status === "Niet leverbaar"
          ) {
            counter = counter + 1;
          }
          if (item.status === "Backorder") {
            this.selectedValue.itemsInBackorder = true;
          }
        });
        if (counter === this.selectedValue.bestelItems.length) {
          this.selectedValue.volledigGeleverd = true;
          this.selectedValue.verwerkt = true;
          this.selectedValue.itemsInBackorder = false;
        }
        const d = new Date().valueOf();
        const log = {
          afterValue: this.selectedValue,
          collection: "Inkoop",
          recordId: this.selectedValue.id,
          type: "edit",
          timeStamp: d.toString(),
        };

        this.$store.dispatch("createLog", log);
        this.$store.dispatch("updateInkoop", this.selectedValue);

        const log2 = {
          afterValue: magazijn,
          collection: "Magazijnen",
          recordId: magazijn.id,
          type: "fysiek",
          timeStamp: d.toString(),
        };

        this.$store.dispatch("createLog", log2);
        this.$store.dispatch("saveStock", {
          id: magazijn.id,
          content: magazijn.stock,
        });
      }, 0);
    },

    updateRec() {
      if (this.volledigeLevering) {
        this.selectedValue.bestelItems.forEach((val) => {
          val.status = "Geleverd";
        });
      } else {
        this.selectedValue.bestelItems.forEach((val) => {
          val.status = "Niet Geleverd";
        });
      }
    },
    verwerktText(value) {
      if (value === true) {
        return "Verwerkt";
      } else {
        return "Nog niet verwerkt";
      }
    },
    getDate(date) {
      if (typeof date === "number") {
        date = +date;
        return this.moment(date).format("DD-MM-YYYY");
      } else if (typeof date === "string") {
        return date;
      }
    },
    closing() {
      this.show = false;
    },
  },

  async created() {
    setTimeout(() => {
      this.selectedValue = this.inkoop.find(
        (_inkoop) => _inkoop.id === +this.$route.params.id
      );
    }, 500);

    this.moment = moment;
  },

};
</script>

<style scoped>
.rootDiv {
  padding: 1rem;
}

.setButton {
  transition: 0.3s all ease-in-out;
}

.editLink {
  transition: 0.3s all ease-in-out;
}

.editLink:hover {
  color: blue;
  cursor: pointer;
  text-decoration: underline;
}

.levering {
  width: 20%;
  border: 1px black solid;
}

.wrapper {
  padding: 1rem;
  border: 1px solid grey;
  border-radius: 12px;
}

.genContent {
  margin: auto;
  width: 90%;
  text-align: left;
}

table {
  margin: auto;
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 90%;
  margin-bottom: 2rem;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

.opmerking {
  font-style: italic;
  font-weight: 700;
}

.btn {
  float: right;
}

.btnUpdate {
  margin-left: 1rem;
  vertical-align: middle;
}

.icon {
  border: 1px solid transparent;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  padding: 0.5rem;
  float: right;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  z-index: 10;
}

.icon:hover {
  border: 1px solid #44bace;
  color: white;
  background-color: #44bace;
}

button {
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 12px;
  background-color: #44bace;
  cursor: pointer;
  color: #fff;
}

button + button {
  margin-right: 1rem;
}

select,
input {
  width: 90%;
  border: grey;
  border-radius: 12px;
  padding: 0.5rem;
}
</style>
