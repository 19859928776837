<template>
  <div class="wrapper">
    <div class="rootDiv">
      <table>
        <th class="productHeader sticky">Producten</th>
        <th
          colspan="1"
          class="dateHeader"
          v-for="m in mutationsById"
          :key="m.id"
          @click="check(m)"
        >
          {{ getDate(m.id) }}
        </th>
        <tr v-for="s in producten" :key="s.productNaam">
          <td class="tableName sticky">{{ s.productNaam }}</td>
          <td
            v-for="m in mutationsById"
            :key="m.id"
            :class="selectedClass(m, s.productNaam)"
          >
            {{ checkValue(m, s.productNaam) }}
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { useLoadMutaties } from "../fb.js";
import moment from "moment";

export default {
  props: ["magazijn"],
  setup() {
    let mutations = useLoadMutaties();
    return { mutations };
  },
  computed: {
    producten() {
      return this.magazijn.stock;
    },

    mutationsById() {
  
      const mut = this.mutations.filter(
        (_mutations) =>
          _mutations.recordId === this.magazijn.id &&
          _mutations.type === "fysiek"
      );
      return mut;
    },
  },
  methods: {
 
    selectedClass(mutation, fieldName) {
      let beforeValue = 0;
      let afterValue = 0;
      if (mutation.beforeValue.stock) {
        mutation.beforeValue.stock.forEach((element) => {
          if (element.productNaam === fieldName) {
            beforeValue = element.fysiekStock;
          }
        });
      }
      if (mutation.afterValue.stock) {
        mutation.afterValue.stock.forEach((element) => {
          if (element.productNaam === fieldName) {
            afterValue = element.fysiekStock;
          }
        });
      }

      if (beforeValue < afterValue) {
        return "increase";
      } else if (beforeValue > afterValue) {
        return "decrease";
      } else {
        return "equal";
      }
    },
    checkValue(mutation, productName) {
      let returnVal = 0;
      const value = mutation.afterValue.stock.filter(
        (val) => val.productNaam === productName
      );

      if (value.length > 0) {
        returnVal = value[0].fysiekStock;
      }
      return returnVal;
    },
    getDate(date) {
      date = +date;
      return this.moment(date).format("DD-MM-YYYY, HH:mm:ss");
    },
  },
  created() {
    this.moment = moment;
  },
};
</script>

<style scoped>
.wrapper {
  padding: 1rem;
}
.rootDiv {
  max-width: 100%;
  overflow: scroll;
  border: 1px solid black;
}

.equal {
  color: black;
}

.increase {
  color: green;
}

.decrease {
  color: red;
}

table {
  border: 1px solid #dddddd;
  margin: auto;
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 2rem;
  overflow: auto;

}

th {
  border: 1px solid darkgrey;
  text-align: center;
  padding: 8px;
}

td {
  border: 1px solid darkgrey;
  text-align: center;
  padding: 1rem;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

.sticky {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: grey;
}

.productHeader {
  vertical-align: middle;

}

.tableName {
  min-width: 15rem;
  font-size: small;

}

.dateHeader {
  font-weight: 100;
  font-size: small;
  text-align: center;
  vertical-align: middle;
  transform: rotate(90deg);
  width: 1%;
  height: 7rem;
}
</style>
